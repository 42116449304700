import { useNavigate, useSearchParams } from "react-router-dom";
import { ReactSession } from "../../lib/secure_reactsession";
import React, { useEffect, useRef, useState } from "react";
import { getFeeRewardSettingsService, getLiveTestService } from "./../../services/test";
import { closeFeedback } from "./../../services/test";
import { Dropdown } from "react-bootstrap";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { encryptId } from "../../lib/helpers.js";
import { LayoutTester } from "../themes/userq/Layouts/layout_tester";
import { Text } from "../themes/userq/Text/Text";
import { TesterTestCard } from "../themes/userq/Test/TesterTestCard";
import { Icon } from "../themes/userq/Icon/Icon";
import { Button } from "../themes/userq/Button/Button";

import { showToast } from "../../lib/helpers";
import WelcomeMessageTesterModal from "../themes/userq/WelcomeMessageModal/welcome_message_tester_modal";
import { Accordian } from "../themes/userq/Accordion/Accordion";

import { updateWelcomeMessage, updateUnblockedPopup } from "../../services/user.js";
import { ToastMessage } from "../themes/userq/ToastMessage/ToastMessage";
import { roundNumber } from "../../lib/helpers.js";
import AccountUnblockedModal from "./modals/account_unblocked_modal";
import { Chip, Chips } from "../themes/userq/Chips/Chips";
import ScreeningQuestionsModal from "../themes/userq/ScreeningQuestionsModal/screening_questions_modal";
import NpsModal from "../themes/userq/NPS/npsModal";
import { Tooltip } from "../themes/userq/Tooltip/Tooltip";
import  {VerifyId} from "../idVerification/VerifyId";
import {ExpiryId} from "../idVerification/ExpiryId";
import {getProfile} from "../../services/user";
import {getBlockedStatus} from "../../services/id_verification";
import {testDefaultCardsData} from "../../data/test-default-card";
import {TesterDefaultcard} from "../themes/userq/Test/TesterDefaultCard";
import toast from "react-hot-toast";



export default function TesterDashboard() {

    const dataFetchedRef = useRef(false);
    const [searchParams] = useSearchParams()
    const autoopenedtest=searchParams.get("test");

    const [welcomeMessageModal, setWelcomeMessageModal] = useState({
        open: false
    });

    const [isPageRefresh, setPageRefresh] = useState(true);
    const [isTestAvailable, setTestAvailable] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [testsLoading, setTestsLoading] = useState(false);
    const [filterLoading, setFilterLoading] = useState(false);

    const [scoreModal, setScoreModal] = useState(false);

    const [tests, setTests] = useState([]);
    const [filter, setFilter] = useState({
        location: "",
        duration: "",
        language: "",
        sort: "desc",
        isSort: false,
    });
    const [settings, setSettings] = useState(null);
    const [isActiveRating, setActiveRating] = useState(true);
    const [isActiveRatingStep2, setActiveRatingStep2] = useState(false);
    const [accountDisabled, setAccountDisabled] = useState(false);
    const [testerscore, setTesterScore] = useState(null);
    const [showUnblockedPopup, setUnblockedPopup] = useState(false);
    const [blockedReason, setBlockedReason] = useState(null);
    const [screeningQuestionsModal, setScreeningQuestionsModal] = useState({
        open: false
    });
    const [test, setTest] = useState(null);
    const [screeningattemptedTests, setScreeningAttemptedTests] = useState([]);
    const [npsModal, setNpsModal] = useState({
        open: false
    });

    const [isProfileVerified, setIsProfileVerified] = useState('');
    const [isProfileVerifiedAt, setIsProfileVerifiedAt] = useState('');
    const [isProfileExpiredAt, setIsProfileExpiredAt] = useState('');
    const [isProfileAttempts, setIsProfileAttempts] = useState(0);

    const [blockedData,setBlockedData] = useState({"days_left":null});

    ReactSession.setStoreType("localStorage");
    const user = ReactSession.get("user");
    const navigate = useNavigate();
    const logout = (e) => {
        e.preventDefault();
        ReactSession.set("token", null);
        ReactSession.set("user", null);
        navigate("/tester/sign-in");
    };

    const [accordianData, setAccordionData] = useState([
        {
            id: 1,
            open: false,
            title: 'Why has my account been blocked?',
            info: "Your account has been blocked due to a tester score of 50% or lower or a violation of our <a class='faq-support-link' href='https://userq.com/terms-of-use/' target='_blank'>Terms of Use</a>."
        },
        {
            id: 2,
            open: false,
            title: 'What happens when my account is blocked?',
            info: 'You will be restricted from accessing any further tests on UserQ, your score will be lowered and you will not be able to request a payout from your wallet.'
        },
        {
            id: 3,
            open: false,
            title: 'How can I get my account unblocked?',
            info: "You can reach out to our <a class='faq-support-link' href='"+process.env.REACT_APP_URL+"t/support/contact?reason=Blocked profile' target='_blank'>support team</a> for advice on how to move forward."
        }
    ]);

    const openScreeningQuestions =(test)=>{
        setScreeningQuestionsModal({ ...screeningQuestionsModal, open: true });
    }

    const [feeRewardSettings, setFeeRewardSettings] = useState([]);

    const getFeeRewardSettingsApi = () => {
        let token = ReactSession.get("token");

        //setPageLoading(true);

    

        getFeeRewardSettingsService().then((response) => {

            //setPageLoading(false);

            if (response.success) {
                setFeeRewardSettings(response.settings);
            } else {
                toast((
                    <ToastMessage type={"error"} message={response.message} closable={true} onClose={() => { toast.dismiss(); }} />
                ), {
                    className: 'errortoast',
                    position: 'bottom-center',
                    duration: settings.toast_duration,
                });
            }
        });
    }

    const renderTests = () => {
        var render = [];
        if (!testsLoading && tests.length > 0) {
            tests.forEach(function (test) {
                render.push(
                    <>
                        <TesterTestCard
                            key={"Testframe" + test.id}
                            test={test}
                            duration={(test.methodology == "Tree Test" || test.methodology == "Survey")
                                ? "5-6 minutes"
                                : test.methodology == "First click" ?
                                    "6-8 minutes"
                                    : (test.methodology == "Card Sorting" || test.methodology == "Prototype test") ?
                                        "10-12 minutes"
                                        : "2-3 minutes"}
                            language={(test.language === 'en') ? 'English' : 'Arabic'}
                            testName={test.test_name}
                            testUrl={(e) => {
                                e.preventDefault();
                                if(test.target_group.is_screening_questions && test.target_group.is_screening_questions==1){
                                    if(screeningattemptedTests.includes(test.id)){
                                        navigate("/t/" + encryptId(test.id, test));
                                    }else{

                                        navigate("/t/screening/" + test.id);
                                        // openScreeningQuestions(test);
                                        // setTest(test);
                                    }
                                }else{
                                navigate("/t/" + encryptId(test.id, test));
                                }
                            }}
                            youWillGet={test.recruitment_fees_for_tester && test.recruitment_fees_for_tester.toFixed(2)}
                        />
                    </>
                );
            });
        } else {
            for (var i = 0; i < 12; i++) {
                render.push(
                    <TesterTestCard
                        key={"Testframe" + i}
                        isLoading={testsLoading}

                    />
                );
            }
        }
        return (
            render
        );
    };


    const getLiveTests = () => {

        let user = ReactSession.get('user');

        if (testsLoading) {
            return false;
        }

        if (dataFetchedRef.current) {
            dataFetchedRef.current.abort();

        }
        const controller = new AbortController();

        dataFetchedRef.current = controller;

        let token = ReactSession.get("token");

        if (!filter.isSort) {
            setFilterLoading(true)
        }
        setTestsLoading(true);
        var data = {};

        data["sort"] = filter.sort;

        data["language"] = filter.language;

        data["duration"] = filter.duration;

        data["location"] = filter.location;

        getLiveTestService(data, token, dataFetchedRef.current?.signal)
            .then((response) => {
                setLoading(false);
                setTestsLoading(false);
                setFilterLoading(false)

                setPageRefresh(false); // set page refresh false

                if (response) {
                    if (response.success) {

                        if(isPageRefresh && response.tests.length > 0){

                            setTestAvailable(true);
                        }

                        setTests(response.tests);

                        setScreeningAttemptedTests(response.attempted_screening);
                        if(autoopenedtest){
                        var opentest=response.tests.filter((test)=>{
                            return test.id==autoopenedtest;
                        });
                        console.log(opentest[0]);
                        openScreeningQuestions(opentest[0]);
                        setTest(opentest[0]);
                        }
                        setSettings(response.settings);
                        setTesterScore(response.score);
                        if (response.user_status == "blocked") {
                            setAccountDisabled(true);
                            setBlockedReason(response.blocked_reason);
                        }
                        if(response.user_status == "active" && response.show_unblocked_popup == 1){
                            setUnblockedPopup(true);

                            //let toast_message = true;
                            let toast_message = user.welcome_message_shown == 1 ? false : true;
                            if(!toast_message) {
                                if (response.is_score_model) {
                                    setScoreModal(true);
                                }
                            }
                        }else{
                            // check model condition $request->user() is_score_model
                            let toast_message = user.welcome_message_shown == 1 ? false : true;
                            if(!toast_message){
                                if(response.is_score_model){
                                    setWelcomeMessageModal({ ...welcomeMessageModal, open: true,isWelcome:false });
                                    updateWelcomeMessage(ReactSession.get("token"))
                                        .then(response => {
                                        });
                                }
                            }
                        }
                        if (response.is_nps == 1) {

                            setNpsModal({...npsModal, open:true});
                            console.log("open nps");
                        }



                    } else {
                        let message = response.message;
                        if (response.message == "Unauthenticated.") {
                            message = "It seems your session has been expired, kindly logout and log in again";
                        }
                        showToast(message, "error");
                    }
                }
            })
            .catch(function (err) {
                showToast(err.message, "error");
                console.error(` Err: ${err}`);
            });
    };



    useEffect(() => {
        document.body.classList.remove('taking-test-body');

        let user = ReactSession.get('user');

        let token = ReactSession.get("token");

        let toast_message = user.welcome_message_shown == 1 ? false : true;

        console.log(user)

        // check profile is verified or not
        // setIsProfileVerified(user.profile_verification_status);
        // setIsProfileVerifiedAt(user.profile_verified_at);

        getProfileData();


        if (toast_message) {
            let user = ReactSession.get("user");
            setWelcomeMessageModal({ ...welcomeMessageModal, open: true,isWelcome:true, is_profile_verified:  (user.profile_verification_status === 'verified')? true:false });

            user.welcome_message_shown = 1;
            ReactSession.set("user", user);
            updateWelcomeMessage(ReactSession.get("token"))
                .then(response => {
                });

        }
        getFeeRewardSettingsApi();

        getLiveTests();
    }, [filter]);

    const getProfileData = ()=>{

        let token = ReactSession.get("token");
        getProfile(token).then((response) => {
            if (response.success) {

                // check profile is verified or not
                setIsProfileVerified(response.data.user.profile_verification_status);
                setIsProfileVerifiedAt(response.data.user.verified_at);

                setIsProfileExpiredAt(response.data.user.document_expiry_date_left);
                setIsProfileAttempts(response.data.user.expired_document_attempt);

                if(response.data.user.profile_verification_status === 'minor' || response.data.user.profile_verification_status === 'duplicate_identity'){

                    getLiveTests();
                    getBlockedStatus(token)
                        .then(response => {

                            if (response.success) {

                                if(response.account_status=="blocked"){
                                    setBlockedData({...blockedData, days_left:response.days_left});
                                } else {
                                    // navigate("/dashboard");
                                }

                            } else {
                                //showError(response.message);
                            }
                        })
                }

            }
        });
    }

    const cancelFeedback = () => {

        const token = ReactSession.get("token");
        const data = new FormData();

        closeFeedback(data, token).then((response) => {
            setActiveRating(true);
            setActiveRatingStep2(false);

            if (response.success) {
                console.log(response.message);
            } else {
                console.log(response.message);
            }
        });

    }

    return (
        <LayoutTester
            isLoading={false}
            isAccount={true}
            skipCheck={false}
            activeMenu={"dashboard"}
        >
            
            {accountDisabled &&
                <div className="dashboard-data-wrapper tester-dashboard disabled-account-dashboard">
                    <div className="page-heading-wrap page-heading-wrap">
                        <Text type={"h1"} children={`Hey, ${user && user.first_name}!`} />
                    </div>

                    {isProfileVerified === 'minor' ?
                        <div className="tester-disbled-account-body">
                            <div className="tester-disbled-account-notice">
                                <Icon colorClass={"danger-red-200"} value={"blocked"} size="large" />
                                <Text type={"h2"} children="Under Age" />
                                <Text type={"subtitle-1"}>
                                    Based on the document provided, it appears that you are under 18 years old. Your account is blocked and will be deleted in {blockedData.days_left} day{blockedData.days_left>1?'s':''}.
                                </Text>
                                <Text type={"body-text-2"}>As per our <a className='faq-support-link disabled-support-link' target="_blank" href={process.env.REACT_APP_SITE_URL+"terms-of-use"}>Terms & Conditions</a>, minors are not allowed to create a tester profile on UserQ.com. If you are not a minor and believe this is an error, please <a className='faq-support-link disabled-support-link' href={process.env.REACT_APP_URL+"t/support/contact?reason=ID Verification"} target="_blank">contact our support team</a>. Otherwise, your registered profile associated with {user && user.email} will be deleted within 30 days.</Text>
                            </div>
                        </div>
                        :
                        <>
                            {isProfileVerified === 'duplicate_identity' ?
                                <div className="tester-disbled-account-body">
                                    <div className="tester-disbled-account-notice">
                                        <Icon colorClass={"danger-red-200"} value={"blocked"} size="large" />
                                        <Text type={"h2"} children="Duplicated identity" />
                                        <Text type={"subtitle-1"} >
                                            It looks like another profile is verified with the same identity! Your account is blocked and will be deleted in {blockedData.days_left} day{blockedData.days_left>1?'s':''}.
                                        </Text>

                                        <Text type={"body-text-2"}>As per our <a className='faq-support-link disabled-support-link' target="_blank" href={process.env.REACT_APP_SITE_URL+"terms-of-use"}>Terms & Conditions</a>, you are not allowed to create multiple profiles on UserQ.com. Each profile is personal, and any attempts to increase earnings in inappropriate ways are monitored by our QA team. If you think this is a mistake, please  <a className='faq-support-link disabled-support-link' href={process.env.REACT_APP_URL+"t/support/contact?reason=ID Verification"} target="_blank">reach out to our support team</a>.<br/> Otherwise, your registered profile associated with {user && user.email} will be deleted within 30 days.</Text>
                                    </div>
                                </div>
                                :
                                <div className="tester-disbled-account-body">
                                    <div className="tester-disbled-account-notice">
                                        <Icon colorClass={"danger-red-200"} value={"blocked"} size="large" />
                                        <Text type={"h2"} children="Important Notice"/>
                                        <Text type={"subtitle-1"}
                                              children="Your profile is blocked and you are unable to participate in new tests."/>
                                        {blockedReason != null &&
                                        <div className="blocked-reason-div">
                                            <Text type={"body-text-2"} fontWeight="semi-bold-font">Reason for
                                                blocking</Text>
                                            <Chips>
                                                {blockedReason.violations && blockedReason.violations.map(function (app) {
                                                    return (<Chip
                                                        key={"app-" + app}
                                                        title={app}
                                                        value={app}
                                                        type={"blocked-profile"}
                                                        onClick={() => {

                                                        }}
                                                    />);
                                                })}
                                            </Chips>
                                            <Text type={"body-text-3"}>{blockedReason.notes}</Text>
                                        </div>
                                        }
                                        <Text type={"body-text-2"}>To resolve this issue, reach out to <a
                                            className='faq-support-link disabled-support-link'
                                            href={process.env.REACT_APP_URL + "t/support/contact?reason=Blocked profile"}
                                            target="_blank">UserQ support</a> to guide you through the next
                                            steps.</Text>
                                    </div>
                                    <div className="tester-disbled-account-accordion">
                                        {accordianData.map((data, index) => (
                                            <Accordian title={data.title} open={data.open}
                                                       onChange={() => {
                                                           if (data.open == true) {
                                                               accordianData[index].open = false;
                                                           } else {
                                                               accordianData[index].open = true;
                                                           }
                                                           setAccordionData([...accordianData]);
                                                       }}
                                            >
                                                <Text type="body-text-2" fontWeight="medium-font">
                                                    <div className="faq-accordion-data"
                                                         dangerouslySetInnerHTML={{__html: data.info}}>
                                                    </div>
                                                </Text>
                                            </Accordian>
                                        ))}
                                    </div>
                                </div>
                            }
                        </>
                    }


                    <a href={process.env.REACT_APP_URL + "t/support"} target="_blank" className='support-button'>
                        <Icon colorClass={"gray-50-svg"} value="support" size={"medium"} />
                        Support
                    </a>
                </div>
            }
            {!accountDisabled  &&
                <>
                {(isProfileVerified === 'id_not_matched_profile' || isProfileVerified === 'unverified' || (isProfileVerified === 'expired_document' && isProfileExpiredAt === "") || isProfileVerified === 'invalid_document') &&
                    <div className="idverify-message mb-32">
                        <VerifyId isType={'banner'} isProfileVerifiedAt={isProfileVerifiedAt} isProfileVerified={isProfileVerified} confirmHandler={(status)=>{
                           if(status){
                               getProfileData();
                           }else{
                               navigate("/t/profile");
                           }

                        }} />
                    </div>
                }


                {((isProfileVerified === 'verified' || isProfileVerified === 'expired_document') && isProfileExpiredAt !== "" && isProfileExpiredAt >=0) &&
                    <div className="idexpiry-message mb-32">
                        <ExpiryId profileExpiredDate={isProfileExpiredAt} isProfileAttempts={isProfileAttempts} isProfileVerifiedAt={isProfileVerifiedAt} confirmHandler={()=>{
                            navigate("/t/profile");
                    }}/>
                    </div>
                }


                
                <div className="dashboard-data-wrapper tester-dashboard">
                    {50 < testerscore && testerscore <= 60 &&
                        <div>
                            <ToastMessage type={"warning"} message={<><p>Your current rating is {roundNumber(testerscore, 0)}%, if your rating falls below 50%, your profile will be blocked, and you won't be eligible for future tests.</p><p>Review our tester guidelines to understand how the rating system works or get in touch with <a href="mailto:support@userq.com">UserQ support.</a></p></>} closable={true} />
                        </div>
                    }
                    <div className="page-heading-wrap page-heading-wrap">

                        <div className="d-flex align-items-center tester-user-head">
                            <Text type={"h1"} cssClasses={"mb-0"} children={`Hey, ${user && user.first_name}!`} /> 
                            {isProfileVerified === 'verified'  &&
                            <span className="tooltip-hold position-relative">
                                <Icon colorClass={"success-green-200-svg"} value={"verified"} size={"medium"} />  
                                <Tooltip data={"Your ID document has been validated and your identity is now verified"} type={"top"}/>
                            </span>
                            }
                        </div>

                        {filterLoading ?
                            <Skeleton width={230} height={30} />
                            :
                            <>
                                {isTestAvailable ?
                                    <Text type={"body-text-1"} fontWeight={"medium-font"} children={`We have ${tests.length} ${(tests.length === 1) ? "test" : "tests"} available for you`} />
                                    :
                                    <Text type={"body-text-1"} fontWeight={"medium-font"} children={"We don't have any tests for you right now :( We'll let you know when one becomes available."} />
                                }
                            </>
                        }

                    </div>

                    <div className={`dashboard-sub-head-wrap ${!isTestAvailable && !testsLoading?'d-none':''}`}>

                        <div className="dashboard-filter-wrap">
                            <div className="dashboard-filter-left d-flex">

                                {isPageRefresh ?
                                    <span className={'filter-skelton'}><Skeleton width={121} height={46} style={{borderRadius:'5px'}} /></span>
                                    :

                                    <Dropdown className="dropdown">
                                        <Dropdown.Toggle className="dropdown-toggle">
                                        <span
                                            className={`filter-menu-btn ${filter.duration !== '' ? "selected-filter" : ""}`}>
                                            {/*filter.duration == "" ? (
                                                "Duration"
                                            ) : filter.duration == "short" ? (
                                                "Short"
                                            ) : filter.duration == "medium" ? (
                                                "Medium"
                                            ) : (
                                                "Long"
                                            )*/}
                                            {feeRewardSettings.map(function(setting){
                                                if(filter.duration==setting.id){
                                                    return setting.duration_label;
                                                }
                                            })}
                                            {!filter.duration &&
                                                <>Duration</>
                                            }
                                            <Icon colorClass="gray-900-svg" size={"medium"} value={"Chevron"} />
                                        </span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <div className={`hamburger-items`}>
                                                <Dropdown.Item
                                                    onClick={(e) => {
                                                        e.preventDefault();

                                                        if (!testsLoading) {
                                                            setFilter({...filter, duration: "", isSort: true});
                                                        }
                                                    }}
                                                    className={`active-item ${(filter.duration == "") ? 'active' : ''}`}
                                                >
                                                    All durations
                                                </Dropdown.Item>
                                                {feeRewardSettings.map(function(setting){
                                                    return (
                                                        <Dropdown.Item
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                if (!testsLoading) {
                                                                    if (filter.duration == setting.id) {
                                                                        setFilter({...filter, duration: "", isSort: true});
                                                                    } else {
                                                                        setFilter({...filter, duration: setting.id, isSort: true});
                                                                    }
                                                                }
                                                            }}
                                                            className={`active-item ${(filter.duration == "short") ? 'active' : ''}`}
                                                        >
                                                            {setting.duration_label}
                                                        </Dropdown.Item>

                                                        
                                                    );
                                                })}

                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                }

                                {isPageRefresh ?
                                    <span className={'filter-skelton'}><Skeleton width={121} height={46}
                                                                                 style={{borderRadius: '5px'}}/></span>
                                    :
                                    <Dropdown className="dropdown">
                                        <Dropdown.Toggle className="dropdown-toggle">
                                        <span
                                            className={`filter-menu-btn ${filter.language !== '' ? "selected-filter" : ""}`}>
                                            {filter.language == "" ? (
                                                "Language"
                                            ) : filter.language == "en" ? (
                                                "English"
                                            ) : (
                                                "Arabic (عربي)"
                                            )}
                                            <Icon colorClass="gray-900-svg" size={"medium"} value={"Chevron"} />
                                        </span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <div className={`hamburger-items`}>
                                                <Dropdown.Item
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        if (!testsLoading) {
                                                            setFilter({...filter, language: "", isSort: true});
                                                        }
                                                    }}
                                                    className={`active-item ${(filter.language == "") ? 'active' : ''}`}
                                                >
                                                    All languages
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        if (!testsLoading) {
                                                            if (filter.language == "en") {
                                                                setFilter({...filter, language: "", isSort: true});
                                                            } else {
                                                                setFilter({...filter, language: "en", isSort: true});
                                                            }
                                                        }
                                                    }}
                                                    className={`active-item ${(filter.language == "en") ? 'active' : ''}`}
                                                >
                                                    English
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        if (!testsLoading) {
                                                            if (filter.language == "ar") {
                                                                setFilter({...filter, language: "", isSort: true});
                                                            } else {
                                                                setFilter({...filter, language: "ar", isSort: true});
                                                            }
                                                        }
                                                    }}
                                                    className={`active-item ${(filter.language == "ar") ? 'active' : ''}`}
                                                >
                                                    Arabic (عربي)
                                                </Dropdown.Item>

                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                }

                                <div className="dashboard-filter-right hide-for-desktop">

                                    <div className="dashboard-sorting-wrap">
                                        {isPageRefresh ?
                                            <span className="filter-sort-btn">
                                                <Skeleton width={160} height={46} style={{borderRadius: '5px'}}/>
                                            </span>
                                            :
                                            <span className="filter-sort-btn" onClick={
                                                () => {
                                                    if (!testsLoading) {
                                                        if (filter.sort === "desc") {
                                                            setFilter({...filter, sort: "asc", isSort: true});
                                                        } else {
                                                            setFilter({...filter, sort: "desc", isSort: true});
                                                        }
                                                    }

                                                }}>

                                                {filter.sort === "desc" ?
                                                    <>
                                                        <span className="desktop-view">Newest to oldest</span>
                                                        <Icon colorClass={"gray-900-svg"} value={"Sort order up"} />
                                                    </>
                                                    :
                                                    <>
                                                        <span className="desktop-view">Oldest to newest</span>
                                                        <Icon colorClass={"gray-900-svg"} value={"Sort order"} />
                                                    </>
                                                }

                                            </span>
                                        }
                                    </div>
                                </div>

                            </div>

                            <div className="dashboard-filter-right hide-for-mobile">

                                <div className="dashboard-sorting-wrap">
                                    {isPageRefresh ?
                                        <span className="filter-sort-btn">
                                                <Skeleton width={160} height={46} style={{borderRadius: '5px'}}/>
                                            </span>
                                        :
                                        <span className="filter-sort-btn" onClick={
                                            () => {
                                                if (!testsLoading) {
                                                    if (filter.sort === "desc") {
                                                        setFilter({...filter, sort: "asc", isSort: true});
                                                    } else {
                                                        setFilter({...filter, sort: "desc", isSort: true});
                                                    }
                                                }

                                            }}>

                                            {filter.sort === "desc" ?
                                                <>
                                                    <span className="desktop-view">Newest to oldest</span>
                                                    <Icon size={"medium"} colorClass="gray-900-svg" value={"Sort order up"} />
                                                </>
                                                :
                                                <>
                                                    <span className="desktop-view">Oldest to newest</span>
                                                    <Icon size={"medium"} colorClass="gray-900-svg" value={"Sort order"} />
                                                </>
                                            }

                                        </span>
                                    }
                                </div>
                            </div>
                        </div>

                    </div>

                    {testsLoading ?
                        <>
                            <div className="all-test-listing-wrapper">
                                {renderTests()}
                            </div>
                        </>
                        :
                        <>
                            <>
                                {tests.length == 0 ?
                                    <div className={`${!isTestAvailable?'':'no-participants-data no-questions-data no-questions-added'}`} style={{ paddingBottom: '20px' }}>

                                        {/*check test is not avallable on loading very first time*/}
                                        {!isTestAvailable ?
                                            <div className="no-page-data-wrap tester-dashboard-no-test-available-data">

                                                <Text type={"h2"} cssClasses={'practice-using-test-demos-text'}>
                                                    Practice using test demos
                                                </Text>

                                                <Text type={"body-text-2"} cssClasses={'practice-using-test-demos-desc'} fontWeight={'medium-font'}>
                                                    To become familiar with how UserQ works while awaiting new tests, you can always practice with the demos below. These exercises are simulated and do not result in any compensation.
                                                </Text>

                                                <div className="tester-dashboard-no-test-available-data-inner">
                                                    {testDefaultCardsData.map((items) => {
                                                        return (
                                                            <TesterDefaultcard
                                                                key={items.title}
                                                                title={items.title}
                                                                targetLink={items.link}
                                                            />
                                                        );
                                                    })}
                                                </div>


                                            </div>
                                            :
                                            <div className="no-page-data-wrap" style={{ marginTop: "80px" }}>
                                                <div className="no-data-icon">
                                                    <Icon value={"information"} size={"large"} />
                                                </div>

                                                <Text type={"subtitle-1"} fontWeight="medium-font">
                                                    We don’t have any studies for you right now :( <br />
                                                    We’ll let you know when one becomes available.
                                                </Text>


                                            </div>
                                        }

                                        <div className="no-tests-rating-information">
                                            <div className="rating-info-left">
                                                <span className={`notests-tester-score score-link-header ${testerscore > 80 ? "green-star" : ""} ${60 < testerscore && testerscore <= 80 ? "purple-star" : ""} ${50 < testerscore && testerscore <= 60 ? "yellow-star" : ""} ${testerscore <= 50 && testerscore != null ? "red-star" : ""} ${testerscore == null ? "white-star" : ""}`}>
                                                    <div className="score-star-icon">
                                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M15.3067 4.66529C15.3631 4.52774 15.4591 4.41006 15.5826 4.32724C15.706 4.24442 15.8513 4.2002 16 4.2002C16.1487 4.2002 16.294 4.24442 16.4174 4.32724C16.5409 4.41006 16.6369 4.52774 16.6933 4.66529L19.5267 11.48C19.5797 11.6075 19.6669 11.7179 19.7786 11.799C19.8904 11.8802 20.0223 11.929 20.16 11.94L27.5173 12.5293C28.1827 12.5826 28.452 13.4133 27.9453 13.8466L22.34 18.6493C22.2353 18.7389 22.1572 18.8556 22.1144 18.9866C22.0716 19.1176 22.0657 19.2578 22.0973 19.392L23.8107 26.572C23.8451 26.716 23.8361 26.867 23.7847 27.006C23.7334 27.1449 23.642 27.2655 23.5222 27.3525C23.4023 27.4395 23.2594 27.4891 23.1114 27.4949C22.9634 27.5008 22.817 27.4626 22.6907 27.3853L16.3907 23.5386C16.273 23.4669 16.1378 23.429 16 23.429C15.8622 23.429 15.727 23.4669 15.6093 23.5386L9.30933 27.3866C9.18301 27.464 9.03661 27.5021 8.88861 27.4963C8.74061 27.4904 8.59767 27.4409 8.47782 27.3538C8.35798 27.2668 8.26662 27.1462 8.21527 27.0073C8.16392 26.8684 8.1549 26.7173 8.18933 26.5733L9.90267 19.392C9.93444 19.2578 9.9286 19.1175 9.88579 18.9865C9.84297 18.8555 9.76485 18.7388 9.66 18.6493L4.05467 13.8466C3.94242 13.7501 3.86121 13.6226 3.82127 13.48C3.78132 13.3375 3.78441 13.1863 3.83015 13.0455C3.8759 12.9047 3.96225 12.7806 4.07835 12.6887C4.19446 12.5969 4.33512 12.5414 4.48267 12.5293L11.84 11.94C11.9777 11.929 12.1096 11.8802 12.2214 11.799C12.3331 11.7179 12.4203 11.6075 12.4733 11.48L15.3067 4.66529Z" stroke="#BFF0A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                        <Text type={"subtitle-1"} fontWeight="medium-font"><span className={`notests-tester-score score-link-header ${testerscore > 80 ? "green-star" : ""} ${60 < testerscore && testerscore <= 80 ? "purple-star" : ""} ${50 < testerscore && testerscore <= 60 ? "yellow-star" : ""} ${testerscore <= 50 && testerscore != null ? "red-star" : ""} ${testerscore == null ? "white-star" : ""}`}>{roundNumber(testerscore)}%</span>is your tester rating</Text>
                                                    </div>

                                                </span>
                                            </div>
                                            <div className="rating-info-right">
                                                <Text type={"body-text-2"} cssClasses={'gray-text'} fontWeight="medium-font">
                                                    Remember, your responses affect your rating, so ensure you answer accurately.<br />
                                                    The better your rating the more tests you will see.
                                                </Text>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="all-test-listing-wrapper">
                                        {renderTests()}
                                    </div>
                                }

                            </>
                        </>
                    }

                    <a href={process.env.REACT_APP_URL + "t/support"} target="_blank" className='support-button'>
                    <Icon colorClass={"gray-50-svg"} value="support" size={"medium"} />
                        Support
                    </a>
                </div>
                </>
            }
            
            <WelcomeMessageTesterModal
                open={welcomeMessageModal.open}
                isWelcome={welcomeMessageModal.isWelcome}
                isProfileVerified={welcomeMessageModal.is_profile_verified}
                confirm={()=>{
                    setWelcomeMessageModal({ ...welcomeMessageModal, open: false })
                    let user = ReactSession.get("user");

                    user.welcome_message_shown = 1;
                    ReactSession.set("user", user);
                    updateWelcomeMessage(ReactSession.get("token"))
                        .then(response => {
                        });
                }}

            />
            {screeningQuestionsModal.open && test &&
            <ScreeningQuestionsModal
                openModal={screeningQuestionsModal.open}
                confirm={()=>{
                    setScreeningQuestionsModal({ ...screeningQuestionsModal, open: false });
                }}
                closeModal={(reloadTests)=>{
                    setScreeningQuestionsModal({ ...screeningQuestionsModal, open: false });
                    if(reloadTests){
                        getLiveTests();
                    }
                }}
                test={test}
            />
            }
            {npsModal.open &&
                        <NpsModal
                            openModal={npsModal.open}
                            confirm={()=>{
                                setNpsModal({ ...npsModal, open: false });
                            }}
                            close={()=>{
                                setNpsModal({ ...npsModal, open: false });
                                cancelFeedback();
                            }}
                            role="tester"
                        />
            }
            <AccountUnblockedModal
            open={showUnblockedPopup}
            close={()=>{
                updateUnblockedPopup(ReactSession.get("token"))
                    .then(response => {
                    });
                setUnblockedPopup(false);}
            }
            title={"Congratulation"}
            subtitle={"Your profile has been unblocked"}
            message={"We are happy to inform you that the issue has been successfully resolved, and your account has been reinstated. To prevent any potential future blocks, we kindly request that you review our"+ <a class='faq-support-link' href={process.env.REACT_APP_URL+"t/tester-guidelines"}>guidelines</a> +".Thank you for your understanding."}
            btn={
                <Button

                        type={"primary"}
                        size={"large"}
                        label={"Go to dashboard"}
                        onClick={() => {
                            updateUnblockedPopup(ReactSession.get("token"))
                                .then(response => {
                                });
                            setUnblockedPopup(false);

                            // show score modal
                            if(scoreModal){

                                setWelcomeMessageModal({ ...welcomeMessageModal, open: true,isWelcome:false });
                                updateWelcomeMessage(ReactSession.get("token"))
                                    .then(response => {
                                    });

                                setScoreModal(false);
                            }
                        }}
                    />
            }
            />
        </LayoutTester>
    );
}