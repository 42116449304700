import PropTypes, { any } from 'prop-types';
import { useEffect, useState } from 'react';
import { Icon } from "../Icon/Icon";

export const RadioButton = ({isChecked, label, img,  sublabel, onChange,name,id,value,})=>{

    const [radioChecked, setRadioChecked] = useState(isChecked);
    const labelClasses = `${radioChecked ? 'active' : ''}`;

    useEffect(()=>{
        setRadioChecked(isChecked);
    },[isChecked])

    return (
        <label htmlFor={id}  className={`radio-btn-wrap ${labelClasses}`}>
            <input
                type="radio"
                id={id}
                checked={radioChecked}
                value={value}
                onChange={(e)=>{

                    // setRadioChecked(!radioChecked);

                    if(onChange){
                        onChange(e);
                    }

                }}
                name={name}
                className="hidden radio-btn-input"  />
            <span className="checkmark"></span>
            {img && (
                <div className='radio-img'>
                    <Icon colorClass={'gray-700-svg'} value={img} />
                </div>
            )}
            {!sublabel &&
            <>
                <p className='radio-btn-label'>
                    {label}
                </p>
            </>
            }
            {sublabel &&
            <div className='radio-btn-labels-wrap'>
                <p className='radio-btn-label semi-bold-font'>
                    {label}
                </p>
                <p className='radio-btn-label '>
                    {sublabel}
                </p>
            </div>
            }





        </label>
    )


}
RadioButton.propTypes = {
    onChange: PropTypes.func,
    isChecked:PropTypes.bool,
    id:PropTypes.string,
    value: PropTypes.any,
    name:PropTypes.string,
    label:PropTypes.node

};
RadioButton.defaultProps = {
    onChange: undefined

};