import {handleApiErrorResponse} from "../lib/helpers";
import axios from 'axios';

// fetch test
export function getTestDashboard(formValues, token,signal) {
  return fetch(
      process.env.REACT_APP_API_END_POINT +
      "test/fetch?status=" +
      formValues.status+"&methodology="+
      formValues.methodology+"&order="+
      formValues.order+"&search="+
      formValues.search+"&workspace_id="+
      (formValues.workspace_id?formValues.workspace_id:"")+"&project_id="+
      (formValues.project_id?formValues.project_id:"")
      ,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        signal:signal
      }
  ).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}


export function getLoadMoreTestDashboard(formValues, token,signal) {
  return fetch(
      process.env.REACT_APP_API_END_POINT +
      "test/load-more?status=" +
      formValues.status+"&methodology="+
      formValues.methodology+"&order="+
      formValues.order+"&workspace_id="+
      (formValues.workspace_id?formValues.workspace_id:"")+"&project_id="+
      (formValues.project_id?formValues.project_id:"")
      ,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        signal:signal
      }
  ).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}

export function getTestCountDashboard(formValues, token,signal) {
  return fetch(
      process.env.REACT_APP_API_END_POINT +
      "test/test-count?status=" +
      formValues.status+"&methodology="+
      formValues.methodology+"&order="+
      formValues.order+"&search="+
      formValues.search
      ,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        signal:signal
      }
  ).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}

export function createTest(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/create", {
    method: "POST",
    body: formValues,
    headers: { Authorization: "Bearer " + token },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function updateTestSettingsService(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/settings/update", {
    method: "POST",
    body: formValues,
    headers: { Authorization: "Bearer " + token },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function saveTest(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/saveintroduction", {
    method: "POST",
    body: formValues,
    headers: { Authorization: "Bearer " + token },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}

export function saveIntroductionQuestions(formValues, token, signal) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/saveIntroductionQuestions", {
    method: "POST",
    body: formValues,
    headers: { Authorization: "Bearer " + token },
    signal:signal
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function duplicateQuestion(formValues, token, signal) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/question/duplicate", {
    method: "POST",
    body: JSON.stringify(formValues),
    headers: {  
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token 
    },
    signal:signal
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function saveScreeningQuestions(formValues, token, signal) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/save-screening-questions", {
    method: "POST",
    body: formValues,
    headers: { Authorization: "Bearer " + token },
    signal:signal
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}

export function saveConclusionQuestions(formValues, token, signal) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/saveConclusionQuestions", {
    method: "POST",
    body: formValues,
    headers: { Authorization: "Bearer " + token },
    signal:signal
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function saveConclusion(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/saveconclusion", {
    method: "POST",
    body: formValues,
    headers: { Authorization: "Bearer " + token },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}

export function saveTargetGroup(formValues, token, signal) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/savetargetgroup", {
    method: "POST",
    body: formValues,
    headers: { Authorization: "Bearer " + token },
    signal: signal
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    console.log(res)
    handleApiErrorResponse(res);
  });
}

export function saveReview(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/saveReview", {
    method: "POST",
    body: formValues,
    headers: { Authorization: "Bearer " + token },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}

export function sendFeedback(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/sendFeedback", {
    method: "POST",
    body: formValues,
    headers: { Authorization: "Bearer " + token },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}

export function closeFeedback(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/closeFeedback", {
    method: "POST",
    body: formValues,
    headers: { Authorization: "Bearer " + token },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function getTestData(formValues, token) {
  return fetch(
    process.env.REACT_APP_API_END_POINT +
      "test/get?test_id=" +
      formValues.test_id+
      (formValues.guest_token ? "&guest_token="+formValues.guest_token : null)+
      (formValues.guest_result_token?"&guest_result_token="+formValues.guest_result_token:"")+
      (formValues.admin_result_token?"&admin_result_token="+formValues.admin_result_token:""),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    }
  ).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}


export function getAddLogicTestResultData(formValues, token) {
  return fetch(
      process.env.REACT_APP_API_END_POINT +
      "researcher/test-results/get-add-logic-test-result?test_id=" +
      formValues.test_id+"&question_id="+formValues.question_id+
      (formValues.guest_result_token?"&guest_result_token="+formValues.guest_result_token:"")+
        (formValues.admin_result_token?"&admin_result_token="+formValues.admin_result_token:""),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }
  ).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}

export function getTestExportData(formValues, token) {
  return fetch(
      process.env.REACT_APP_API_END_POINT +
      "test/test-export?id=" +
      formValues.id,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }
  ).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function getTestOrderData(formValues, token) {
  return fetch(
    process.env.REACT_APP_API_END_POINT +
      "test/order/get?test_id=" +
      formValues.test_id,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    }
  ).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function getTestsService(formValues, token) {
  
  return fetch(
    process.env.REACT_APP_API_END_POINT +
      "test/list?project_id=" +
      formValues.project_id,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    }
  ).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function addNodeService(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/tree/node/create", {
    method: "POST",
    body: JSON.stringify(formValues),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function removeNodeService(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/tree/node/remove", {
    method: "POST",
    body: JSON.stringify(formValues),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function removeNodesService(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/tree/nodes/remove", {
    method: "POST",
    body: JSON.stringify(formValues),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function addTaskService(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/tree/task/create", {
    method: "POST",
    body: JSON.stringify(formValues),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function updateTreeService(formValues, token, signal) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/tree/save", {
    method: "POST",
    body: JSON.stringify(formValues),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    signal:signal
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function updateTreeNodeService(formValues, token, signal) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/tree/node/update", {
    method: "POST",
    body: JSON.stringify(formValues),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    signal:signal
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function updateNodePositionService(formValues, token, signal) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/tree/node/updateNodePosition", {
    method: "POST",
    body: JSON.stringify(formValues),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    signal:signal
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function updateTreeTaskService(formValues, token, signal) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/tree/task/update", {
    method: "POST",
    body: JSON.stringify(formValues),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    signal:signal
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function updateTreeTasksService(formValues, token, signal) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/tree/tasks/update", {
    method: "POST",
    body: JSON.stringify(formValues),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    signal:signal
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function removeTreeTaskService(formValues, token, signal) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/tree/task/remove", {
    method: "POST",
    body: JSON.stringify(formValues),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    signal:signal
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function updateHomeNodeService(formValues, token, signal) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/tree/node/updateHomeNode", {
    method: "POST",
    body: JSON.stringify(formValues),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    signal:signal
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function updatePublishSettingsService(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/publish/settings/save", {
    method: "POST",
    body: JSON.stringify(formValues),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function deleteTestService(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/delete", {
    method: "POST",
    body: JSON.stringify(formValues),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function renameTestService(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/rename", {
    method: "POST",
    body: JSON.stringify(formValues),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function publishTestService(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/publish", {
    method: "POST",
    body: JSON.stringify(formValues),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function importCSVService(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/tree/import", {
    method: "POST",
    body: formValues,
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}

export function changeTestStatus(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/changestatus", {
    method: "POST",
    body: formValues,
    headers: { Authorization: "Bearer " + token },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}

export function changeTestLanguage(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/changelanguage", {
    method: "POST",
    body: formValues,
    headers: { Authorization: "Bearer " + token },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}

export function deleteTest(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/delete", {
    method: "POST",
    body: formValues,
    headers: { Authorization: "Bearer " + token },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}

export function duplicateTest(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/duplicatetest", {
    method: "POST",
    body: formValues,
    headers: { Authorization: "Bearer " + token },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}

export function moveTestToAnotherProject(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/move", {
    method: "POST",
    body: formValues,
    headers: {"Content-Type": "application/json",
    Accept: "application/json", Authorization: "Bearer " + token },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}

export function updateTestStep(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/updateStep", {
    method: "POST",
    body: formValues,
    headers: {"Content-Type": "application/json",
    Accept: "application/json", Authorization: "Bearer " + token },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function getLiveTestService(formValues, token,signal) {
  return fetch(process.env.REACT_APP_API_END_POINT + "tester/tests/list/live?sort="+formValues.sort+"&language="+formValues.language+"&duration="+formValues.duration+"&location="+formValues.location, {
    method: "GET",
    headers: { "Content-Type": "application/json",
    Accept: "application/json",Authorization: "Bearer " + token },
    signal:signal,
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function addDesignService(formValues, token) {
  return   fetch(process.env.REACT_APP_API_END_POINT + "test/preference-test/design/create", {
    method: "POST",
    body: formValues,
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res)=>{

    
    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function addFirstclickDesignService(formValues, token) {
  return   fetch(process.env.REACT_APP_API_END_POINT + "test/firstclick-test/design/create", {
    method: "POST",
    body: formValues,
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res)=>{

    
    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function addImportDesignService(formValues, token, progressCallback) {
  return   axios.post(process.env.REACT_APP_API_END_POINT + "test/preference-test/design/create",formValues, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      const {loaded, total} = progressEvent;
      let percent = Math.floor((loaded * 100) / total);
      progressCallback(percent)
    }
  }).then((res)=>{

    
    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.data : null;

    if (res.statusText!="OK") {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }
console.log(data)
    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function addImportFirstclickDesignService(formValues, token, progressCallback) {
  return   axios.post(process.env.REACT_APP_API_END_POINT + "test/firstclick-test/design/create",formValues, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      const {loaded, total} = progressEvent;
      let percent = Math.floor((loaded * 100) / total);
      progressCallback(percent)
    }
  }).then((res)=>{

    
    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.data : null;

    if (res.statusText!="OK") {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }
console.log(data)
    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function deleteDesignService(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/preference-test/design/remove", {
    method: "POST",
    body: JSON.stringify(formValues),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function deleteFirstclickDesignService(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/firstclick-test/design/remove", {
    method: "POST",
    body: JSON.stringify(formValues),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function deletePrototypeTestTaskService(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/prototype-test/task/remove", {
    method: "POST",
    body: JSON.stringify(formValues),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function deleteFirstclickTestTaskService(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/firstclick-test/task/remove", {
    method: "POST",
    body: JSON.stringify(formValues),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function reorderDesignService(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/preference-test/design/reorder", {
    method: "POST",
    body: JSON.stringify(formValues),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function updatePreferenceTestService(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/preference-test/save", {
    method: "POST",
    body: JSON.stringify(formValues),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function updatePrototypeTestService(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/prototype-test/save", {
    method: "POST",
    body: JSON.stringify(formValues),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function updateFirstclickTestService(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/firstclick-test/save", {
    method: "POST",
    body: JSON.stringify(formValues),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function addPrototypeTestTaskService(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/prototype-test/task/add", {
    method: "POST",
    body: JSON.stringify(formValues),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}

export function addFirstclickTestTaskService(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/firstclick-test/task/add", {
    method: "POST",
    body: JSON.stringify(formValues),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}

export function syncPrototypeLinkService(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/prototype-test/syncprototype", {
    method: "POST",
    body: JSON.stringify(formValues),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function validateFigmaTokenService(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/prototype-test/token/validate", {
    method: "POST",
    body: JSON.stringify(formValues),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function connectWithFigmaService(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/prototype-test/figma/connect", {
    method: "POST",
    body: JSON.stringify(formValues),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function unsyncPrototypeTestTaskService(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/prototype-test/task/unsync", {
    method: "POST",
    body: JSON.stringify(formValues),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function savePrototypeTestTaskService(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/prototype-test/task/save", {
    method: "POST",
    body: JSON.stringify(formValues),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function saveFirstclickTestTaskService(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/firstclick-test/task/save", {
    method: "POST",
    body: JSON.stringify(formValues),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function getPrototypeTestTaskQuestionsService(formValues, token) {
  return fetch(
      process.env.REACT_APP_API_END_POINT +
      "test/prototype-test/task/questions?test_id=" +
      formValues.test_id+"&task_id="+
      formValues.task_id
      ,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }
  ).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function updatePrototypeTestTaskGoalScreenService(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/prototype-test/task/goal-screen", {
    method: "POST",
    body: JSON.stringify(formValues),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function resyncPrototypeTaskService(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/prototype-test/task/resync", {
    method: "POST",
    body: JSON.stringify(formValues),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}

export function saveSurveyQuestions(formValues, token,signal) {
  return fetch(
    process.env.REACT_APP_API_END_POINT + "test/saveSurveyQuestions",
    {
      method: "POST",
      body: formValues,
      headers: { Authorization: "Bearer " + token },
      signal:signal,
    }

  ).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}

export function saveCardSortingTestService(formValues, token,signal) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/card-sorting/save", {
    method: "POST",
    body: JSON.stringify(formValues),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    signal:signal
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}

export function saveFiveSecondsTestData(formValues, token, signal) {
    return fetch(
      process.env.REACT_APP_API_END_POINT + "test/five-seconds-test/save",
      {
        method: "POST",
        body: formValues,
        headers: { Authorization: "Bearer " + token },
        signal: signal
      }
    ).then((res)=>{
  
      const isJson = res.headers.get('content-type')?.includes('application/json');
      const data = isJson ? res.json() : null;
  
      if (!res.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || res.status;
        return Promise.reject(error);
      }
  
      return data;
  
    }).catch((res)=>{
      handleApiErrorResponse(res);
    });
  }

  export function saveFiveSecondsTestTask(formValues, token, signal) {
    return fetch(
      process.env.REACT_APP_API_END_POINT + "test/five-seconds-test/saveTask",
      {
        method: "POST",
        body: formValues,
        headers: { Authorization: "Bearer " + token },
        signal: signal
      }
    ).then((res)=>{
  
      const isJson = res.headers.get('content-type')?.includes('application/json');
      const data = isJson ? res.json() : null;
  
      if (!res.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || res.status;
        return Promise.reject(error);
      }
  
      return data;
  
    }).catch((res)=>{
      handleApiErrorResponse(res);
    });
  }

export function addFiveSecondsDesignService(formValues, token, progressCallback) {
  return   axios.post(process.env.REACT_APP_API_END_POINT + "test/five-seconds-test/design/create",formValues, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      const {loaded, total} = progressEvent;
      let percent = Math.floor((loaded * 100) / total);
      progressCallback(percent)
    }
  }).then((res)=>{


    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.data : null;

    if (res.statusText!="OK") {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }
    console.log(data)
    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
  /*export function addFiveSecondsDesignService(formValues, token,progressCallback) {
    return   axios.post(process.env.REACT_APP_API_END_POINT + "test/five-seconds-test/design/create", {
      method: "POST",
      body: formValues,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const {loaded, total} = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        progressCallback(percent)
      }
    }).then((res)=>{
  
      const isJson = res.headers.get('content-type')?.includes('application/json');
      const data = isJson ? res.json() : null;
  
      if (!res.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || res.status;
        return Promise.reject(error);
      }
  
      return data;
  
    }).catch((res)=>{
      handleApiErrorResponse(res);
    });
  }*/


  export function removeFiveSecondsDesignService(formValues, token) {
    return   fetch(process.env.REACT_APP_API_END_POINT + "test/five-seconds-test/design/remove", {
      method: "POST",
      body: formValues,
      headers: {
        Authorization: "Bearer " + token,
      },
    }).then((res)=>{
  
      const isJson = res.headers.get('content-type')?.includes('application/json');
      const data = isJson ? res.json() : null;
  
      if (!res.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || res.status;
        return Promise.reject(error);
      }
  
      return data;
  
    }).catch((res)=>{
      handleApiErrorResponse(res);
    });
  }

export function importCardSortingCardCSVService(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT + "test/card-sorting/import-card", {
        method: "POST",
        body: formValues,
        headers: {
            Authorization: "Bearer " + token,
        },
    }).then((res)=>{

      const isJson = res.headers.get('content-type')?.includes('application/json');
      const data = isJson ? res.json() : null;

      if (!res.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || res.status;
        return Promise.reject(error);
      }

      return data;

    }).catch((res)=>{
      handleApiErrorResponse(res);
    });
}

export function importCardSortingCategoryCSVService(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT + "test/card-sorting/import-category", {
        method: "POST",
        body: formValues,
        headers: {
            Authorization: "Bearer " + token,
        },
    }).then((res)=>{

      const isJson = res.headers.get('content-type')?.includes('application/json');
      const data = isJson ? res.json() : null;

      if (!res.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || res.status;
        return Promise.reject(error);
      }

      return data;

    }).catch((res)=>{
      handleApiErrorResponse(res);
    });
}

export function savePreferenceTestQuestions(formValues, token, signal) {
  return fetch(
    process.env.REACT_APP_API_END_POINT + "test/preference-test/save/questions",
    {
      method: "POST",
      body: formValues,
      headers: { Authorization: "Bearer " + token },
      signal: signal
    }
  ).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function savePrototypeTestTaskQuestions(formValues, token, signal) {
  return fetch(
    process.env.REACT_APP_API_END_POINT + "test/prototype-test/save/questions",
    {
      method: "POST",
      body: formValues,
      headers: { Authorization: "Bearer " + token },
      signal: signal
    }
  ).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function saveTreeTestTaskQuestions(formValues, token, signal) {
  return fetch(
    process.env.REACT_APP_API_END_POINT + "test/tree/save/questions",
    {
      method: "POST",
      body: formValues,
      headers: { Authorization: "Bearer " + token },
      signal: signal
    }
  ).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function saveFirstclickTestTaskQuestions(formValues, token, signal) {
  return fetch(
    process.env.REACT_APP_API_END_POINT + "test/firstclick-test/save/questions",
    {
      method: "POST",
      body: formValues,
      headers: { Authorization: "Bearer " + token },
      signal: signal
    }
  ).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function updatePrototypeTestTasksOrderService(formValues, token, signal) {
  return fetch(
    process.env.REACT_APP_API_END_POINT + "test/prototype-test/tasks/order/update",
    {
      method: "POST",
      body: formValues,
      headers: { Authorization: "Bearer " + token },
      signal: signal
    }
  ).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function updateFirstclickTestTasksOrderService(formValues, token, signal) {
  return fetch(
    process.env.REACT_APP_API_END_POINT + "test/firstclick-test/tasks/order/update",
    {
      method: "POST",
      body: formValues,
      headers: { Authorization: "Bearer " + token },
      signal: signal
    }
  ).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function updateTreeTestTasksOrderService(formValues, token, signal) {
  return fetch(
    process.env.REACT_APP_API_END_POINT + "test/tree/tasks/order/update",
    {
      method: "POST",
      body: formValues,
      headers: { Authorization: "Bearer " + token },
      signal: signal
    }
  ).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}

export function saveTestResultPasswordService(formValues, token,signal) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/update-test-result-password", {
    method: "POST",
    body: JSON.stringify(formValues),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    signal:signal
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function getTestLengthService(formValues, token,signal) {
  return fetch(
      process.env.REACT_APP_API_END_POINT +
      "test/length?test_id=" +
      formValues.test_id,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        signal:signal
      }
  ).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function getFeeRewardSettingsService() {
  return fetch(
    process.env.REACT_APP_API_END_POINT +
      "test/fee_reward/settings",
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
    }
  ).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function downloadFileService(formValues, token) {
  return fetch(
      process.env.REACT_APP_API_END_POINT +
      "download/files?fileSource=" +
      formValues.file      ,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          Accept:"application/octet-stream"
        }
      }
  ).then((response)=>{

    const isJson = response.headers.get('content-type')?.includes('application/json');
    //const data = isJson ? response.json() : null;

    // Ensure the response is OK (status 200)
    if (!response.ok) {
        const error =  response.status;
        throw error;
    }

    /*if (!res.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || res.status;
        return Promise.reject(error);
    }*/
    const contentType = response.headers.get('Content-Type');


    if (contentType && contentType.includes('application/json')) {
        return response.json().then(data => {
            if(!data.success) {
                if(data.message=="Access not allowed") {
                    //setNoAccess(true);
                }
            }
            //console.log('Received JSON:', data);
            // Handle the JSON data (you can display it or process it further)
        });
    }
    if (contentType && contentType.includes('application/octet-stream')) {
        // Extract the Content-Disposition header to get the filename
        const contentDisposition = response.headers.get('Content-Disposition');
        let filename = 'downloaded-file.pdf'; // Default filename

        if (contentDisposition) {
            const filenameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
            if (filenameMatch != null && filenameMatch[1]) {
                filename = filenameMatch[1].replace(/['"]/g, ''); // Remove any surrounding quotes
            }
        }

        // Get the PDF data as a Blob
        response.blob().then(blob => {
          // Create a temporary URL for the Blob
          const url = window.URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }));

          // Create a temporary link element
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename); // Set the filename to the one extracted

          // Append link to the body, trigger the click, then remove it
          document.body.appendChild(link);
          link.click();

          // Clean up: revoke the object URL and remove the link element
          link.parentNode.removeChild(link);
          window.URL.revokeObjectURL(url);
        });

        
    }

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
