import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { ReactSession } from "../../../lib/secure_reactsession.js";
import React, { useState, useRef, useEffect } from "react";
import { LayoutResearcher } from "../../themes/userq/Layouts/layout_researcher";
import { Icon } from "../../themes/userq/Icon/Icon";
import { Text } from "../../themes/userq/Text/Text";
import { Button } from "../../themes/userq/Button/Button";
import { testDefaultCardsData } from "../../../data/test-default-card";
import { Defaultcard } from "../../themes/userq/Test/DefaultCard";
import WelcomeMessageModal from "../../themes/userq/WelcomeMessageModal/welcome_message_modal.js";
import { CircularNameIcon } from "../../themes/userq/CircularNameIcon/CircularNameIcon.js";
import {
    dismissAcceptedModalService,
    getWorkspaceService,
    updateWorkspaceSuccessMessage,
    setDefaultWorkspaceService,
} from "../../../services/workspaces.js";
import {
    updateWelcomeMessage,
    updateNewFeatureService,
} from "../../../services/user.js";
import { Helmet } from "react-helmet";
import WelcomeCongratsModal from "./modals/welcome-congrats-modal.js";
import AcceptedWorkspaceModal from "./modals/accepted-workspace-modal.js";
import { containsArabic, showToast } from "../../../lib/helpers";
import { atom, useRecoilState, useResetRecoilState } from "recoil";
import {
    changeTestStatus,
    deleteTest,
    duplicateTest,
} from "../../../services/test";
import {
    closeFeedback,
    getTestDashboard,
    getLoadMoreTestDashboard,
} from "../../../services/test.js";
import toast from "react-hot-toast";
import moment from "moment";
import TestSettingsModal from "../../dashboard/modals/test_settings_modal";
import ChooseMethodologyModal from "../../dashboard/modals/choose_methodologies_model";
import { Dropdown } from "react-bootstrap";
import CopyLinkModal from "../../dialog/copy_link_modal";
import TestSummaryModal from "../../dialog/test_summary_modal";

import ConfirmationModal from "../../themes/userq/Modal/ConfirmationModal";

import { settings } from "../../../data/settings";

import { ToastMessage } from "../../themes/userq/ToastMessage/ToastMessage";
import { CardTemplate } from "../../themes/userq/Test/Card";

import { encryptId, roundNumber } from "../../../lib/helpers";
import NewFeatureModal from "../../themes/userq/Modal/NewFeatureModal";
import { Tag } from "../../themes/userq/Tag/Tag";
import HelpModal from "../../themes/userq/Modal/HelpModal";
import { Box } from "../../themes/userq/Box/Box";
import { RadioButton } from "../../themes/userq/RadioButton/RadioButton";
import NpsModal from "../../themes/userq/NPS/npsModal";
import { Search } from "../../themes/userq/Search/Search";
import { Link as UQLink } from "../../themes/userq/Link/Link";
import Skeleton from "react-loading-skeleton";

import { LoadingIcon } from "../../themes/userq/Loader/loadingIcon";

import ReviewPaymentModal from "./modals/review-payment-modal.js";

import { getWorkspaceBillingService } from "../../../services/workspaces.js";

import { getPaymentMethodsService } from "../../../services/payment_methods.js";

import validator from "validator";
import { getWorkspaceProjectService } from "../../../services/workspaceprojects.js";
import ProjectMembersModal from "./modals/project-members-modal.js";
import MoveTestModal from "./modals/move-test-modal.js";
import "./dashboard.css";
import { allowEditAccessService } from "../../../services/heartbeat.js";
import { useTestBuilderData } from "../tests/contexts/TestBuilderContext.js";
import InviteMembersModal from "./modals/invite-members-modal.js";

let movingTestId = "";

export default function WorkspaceDashboard() {
    ReactSession.setStoreType("localStorage");

    const location = useLocation();

    const [user, setUser] = useState({});

    const [testTypeClicked, setTestTypeClicked] = useState(false);

    const { setAllowEditAccessModal, events, setFrozenWorkspaceModal } =
        useTestBuilderData();

    const [refreshLoading, setRefreshLoading] = useState(null);

    const [show_support_panel, setShowSupportPanel] = useState(false);

    const [welcomeMessageModal, setWelcomeMessageModal] = useState({
        open: false,
    });
    const [acceptedWorkspaceModal, setAcceptedWorkspaceModal] = useState({
        open: false,
    });

    const { workspaceid, project_id } = useParams();

    const [isLoading, setLoading] = useState(false);

    const [workspace, setWorkspace] = useState(null);

    const [project, setProject] = useState(null);

    const [teamMembers, setTeamMembers] = useState([]);

    const [openCongratsModal, setOpenCongratsModal] = useState({
        open: false,
        type: null,
    });

    const navigate = useNavigate();

    const dataFetchedRef = useRef(false);

    const dataFetchedSearchRef = useRef(false);

    const searchRef = useRef(null);

    const [isPageRefresh, setPageRefresh] = useState(true);
    const [isLoadMoreTestLoading, setLoadMoreTestLoading] = useState(false);
    const [isFilterLoading, setIsFilterLoading] = useState(false);
    const [confirmationBtnLoading, setConfirmationBtnLoading] = useState(false);
    const [duplicateBtnLoading, setDuplicateBtnLoading] = useState(false);
    const [isTestAvailable, setIsTestAvailable] = useState(true);
    const [isApplyFilter, setIsApplyFilter] = useState(false);
    const [toggleMethodology, setToggleMethodology] = useState(false);
    const [tests, setTests] = useState([]);
    const [openNewTestModal, setOpenNewTestModal] = useState(false);
    const [openChooseMethodologiesModal, setOpenChooseMethodologiesModal] =
        useState(false);
    const [openNewFeatureModal, setOpenNewFeatureModal] = useState({
        open: false,
    });
    const [modalMethodology, setModalMethodology] = useState("");
    const [totalTests, setTotalTests] = useState(1);
    const [isTestCount, setTestCount] = useState(0);
    const [filterTestCount, setFilterTestCount] = useState(0);
    const [totalTestsCount, setTotalTestsCount] = useState({
        Card_Sorting_count: 1,
        Preference_Test_count: 1,
        Tree_Test_test: 1,
        Survey_count: 1,
        Five_Seconds_Test_count: 1,
        First_Click_count: 1,
        prototype_test_count: 1,
    });
    const [statusTestsCount, setStatusTestsCount] = useState({
        all: 0,
        draft: 0,
        published: 0,
        completed: 0,
    });

    const creditsState = atom({
        key: "credits",
        default: 0,
    });

    const [search, setSearch] = useState("");

    const [isCheckSearch, setCheckSearch] = useState(false);

    const reset = useResetRecoilState(creditsState);

    const [credits, setCredits] = useRecoilState(creditsState);

    const [enableSearch, setEnableSearch] = useState(false);
    const toggleSearch = () => {
        setEnableSearch((prevState) => !prevState);
    };

    const [confirmModal, setConfirmModal] = useState({
        open: false,
    });

    const [copyLinkModal, setCopyLinkModal] = useState({
        open: false,
    });
    const [testSummaryModal, setTestSummaryModal] = useState({
        open: false,
    });
    const [duplicateModal, setDuplicateModal] = useState({
        open: false,
    });
    const [npsModal, setNpsModal] = useState({
        open: false,
    });

    let methodologies = {
        "Prototype test": "Prototype test",
        "Card Sorting": "Card sorting",
        "Preference Test": "Preference test",
        "Tree Test": "Tree test",
        Survey: "Survey",
        "Five Seconds Test": "5 second test",
        "First click": "First click",
    };

    const [formValues, setFormValues] = useState({
        status: "",
        methodology: "",
        order: "desc",
        loading: false,
        workspace_id: null,
    });

    const [publishing_credits, setPublishingCredits] = useState(0);

    const [publishing_credits_meth, setPublishingCreditsMeth] = useState([]);

    const [days_left, setDaysLeft] = useState(0);

    const [teamMember, setTeamMember] = useState(null);

    const [projectMembersModal, setProjectMembersModal] = useState({
        open: false,
    });

    const [moveTestModal, setMoveTestModal] = useState({
        open: false,
    });

    const [openFrozenModal, setOpenFrozenModal] = useState(false);

    const [inviteMemberModal, setInviteMemberModal] = useState({
        open: false,
    });

    useEffect(() => {
        events.forEach(function (event) {
            if (event.event_type === "frozen_workspace_active") {
                if (workspace && workspace.id === event.event_id) {
                    let workspaceLoc = Object.assign({}, workspace);
                    workspaceLoc.status = "active";

                    setWorkspace(workspaceLoc);

                    setFrozenWorkspaceModal({ open: false });
                }
            }
        });
    }, [events]);

    useEffect(() => {
        movingTestId = ReactSession.get("test_moving_id");

        getWorkspaceProjectApi();
    }, [project_id]);

    const getWorkspaceProjectApi = () => {
        let token = ReactSession.get("token");
        setLoading(true);
        getWorkspaceProjectService(token, project_id)
            .then((response) => {
                if (response.success) {
                    //setDefaultWorkspace();
                    setWorkspace(response.workspace);
                    setProject(response.workspace_project);
                    setLoading(false);

                    if (
                        response.workspace_project &&
                        response.workspace_project_team_member
                    ) {
                        if (
                            response.workspace_project_team_member.status !==
                            "active"
                        ) {
                            navigate(
                                "/wsp/" +
                                    response.workspace_project.workspace_id +
                                    "/p/" +
                                    response.workspace_project.id +
                                    "/project-no-access"
                            );
                        }
                    }
                    if (response.workspace && response.workspace_team_member) {
                        setTeamMember(response.workspace_team_member);
                        if (
                            response.workspace_team_member.status !==
                                "invitation_accepted" &&
                            response.workspace_team_member.status !== "active"
                        ) {
                            navigate(
                                "/wsp/" +
                                    response.workspace_project.workspace_id +
                                    "/noaccess"
                            );
                        }
                    }
                    getTests(true);
                } else {
                    if (response.message == "Workspace not found.") {
                        navigate("/404/");
                    } else if (
                        response.message == "Workspace project not found."
                    ) {
                        navigate("/404/");
                    } else {
                        setLoading(false);
                        showToast(response.message, "error");
                    }
                }
            })
            .catch((error) => {
                console.error("Error fetching workspace:", error);

                setLoading(false);
            });
    };

    const setDefaultWorkspace = () => {
        let token = ReactSession.get("token");
        setLoading(true);
        let formData = {};
        formData.workspace_id = workspaceid;
        setDefaultWorkspaceService(formData, token)
            .then((response) => {
                setLoading(false);
                if (response.success) {
                } else {
                    showToast(response.message, "error");
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const [workspaceCreated, setworkspaceCreated] = useState(false);

    const [createdTeamWorkspace, setCreatedTeamWorkspace] = useState(true);

    const deleteTestApi = (test_id) => {
        let token = ReactSession.get("token");
        setConfirmationBtnLoading(true);
        var data = new FormData();
        data.append("test_id", test_id);
        deleteTest(data, token)
            .then((response) => {
                setConfirmationBtnLoading(false);
                setConfirmModal({ ...confirmModal, open: false });

                if (response.success) {
                    showSuccessToast(response.message);
                    // searchData("");
                    getTests(false);
                } else {
                    showErrorToast(response.message);
                }
            })
            .catch(function (err) {
                showErrorToast(err);
            });
    };

    const endTestApi = (test_id) => {
        let token = ReactSession.get("token");

        setConfirmationBtnLoading(true);
        var data = new FormData();
        data.append("test_id", test_id);
        data.append("status", "completed");
        changeTestStatus(data, token)
            .then((response) => {
                setConfirmationBtnLoading(false);
                setConfirmModal({ ...confirmModal, open: false });

                if (response.success) {
                    if (response.refundedCredits > 0) {
                        showSuccessToast(
                            `Your test has ended. ${response.refundedCredits} credits have been refunded to your wallet.`
                        );
                        //reset();

                        setCredits(response.credits_balance);
                    } else {
                        showSuccessToast("Your test has ended successfully.");
                    }

                    ReactSession.set("credits", response.credits_balance);
                    //setCredits(response.credits_balance);
                    //navigate('/r/credits');
                    // searchData("");
                    getTests(false);
                }
            })
            .catch(function (err) {
                showErrorToast(err);
            });
    };

    const duplicateTestApi = (test_id) => {
        let token = ReactSession.get("token");

        setIsFilterLoading(true);
        var data = new FormData();
        data.append("test_id", test_id);

        data.append(
            "duplicate_preference",
            duplicateModal.duplicate_preference
        );

        duplicateTest(data, token)
            .then((response) => {
                setIsFilterLoading(false);
                setConfirmModal({ ...confirmModal, open: false });

                setDuplicateModal({ ...duplicateModal, open: false });

                if (response.success) {
                    // searchData("");
                    getTests(false);
                    document.getElementById("test-filters").scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                    });
                }
            })
            .catch(function (err) {
                showErrorToast(err);
            });
    };

    const getTests = (loading) => {
        let token = ReactSession.get("token");

        ReactSession.set("enableLoadingData", "yes");

        if (dataFetchedRef.current) {
            dataFetchedRef.current.abort();
        }

        //abort search controller
        if (dataFetchedSearchRef.current) {
            dataFetchedSearchRef.current.abort();
        }

        const controller = new AbortController();

        dataFetchedRef.current = controller;

        // if(loading){
        //     setLoading(true);
        // }else {
        setIsFilterLoading(true);
        //}
        // setLoading(true);
        setLoadMoreTestLoading(false);

        setTestTypeClicked(false);

        getTestDashboard(
            {
                ...formValues,
                workspace_id: workspaceid,
                project_id: project_id,
                search: search,
            },
            token,
            dataFetchedRef.current?.signal
        ).then((response) => {
            if (response.success) {
                setTests(response.tests);

                // check after filter data records exists
                if (response.tests.length === 0 && !loading) {
                    setIsTestAvailable(false);
                }
                setPublishingCredits(response.publishing_credits);

                setPublishingCreditsMeth(response.publishing_credits_meth);

                setDaysLeft(response.days_left);

                // set filter test count
                setFilterTestCount(response.tests.length);

                setStatusTestsCount(response.status_count);

                // check more record exists then load more data
                if (response.status_count.more_records) {
                    loadMoreTestsData();
                } else {
                    ReactSession.set("enableLoadingData", "no");
                }

                // set test count
                setTestCount(response.is_test_available);

                setNpsModal({
                    ...npsModal,
                    open: response.is_nps == 1 ? true : false,
                });

                setCheckSearch(true);

                // set total test count calculateTotalCount(formValues.methodology,val);
                if (response.totalTestCount) {
                    // setTotalTests(response.totalTestCount + 1);
                    setTotalTestsCount(response.totalTestCount);
                }

                if (response.new_feature_available) {
                    // setTotalTests(response.totalTestCount + 1);
                    setOpenNewFeatureModal({
                        ...openNewFeatureModal,
                        open: true,
                    });
                }
            } else {
                setTests([]);
                showErrorToast(response.message);
            }

            // if(loading){
            // setLoading(false);
            //setIsApplyFilter(true);
            //}else {
            setIsFilterLoading(false);
            setLoading(false);
            setPageRefresh(false);
            // }
        });
    };

    const loadMoreTestsData = () => {
        let token = ReactSession.get("token");

        if (dataFetchedRef.current) {
            dataFetchedRef.current.abort();
        }

        const controller = new AbortController();

        dataFetchedRef.current = controller;

        setLoadMoreTestLoading(true);
        // console.log(tests)
        // console.log(formValues)

        getLoadMoreTestDashboard(
            {
                ...formValues,
                workspace_id: workspaceid,
                project_id: project_id,
            },
            token,
            dataFetchedRef.current?.signal
        ).then((response) => {
            setLoadMoreTestLoading(false);

            setTestTypeClicked(false);

            if (response.success) {
                // setTests(response.tests);

                //var totalTest = tests.concat(response.tests);
                var totalTest = response.tests;

                setCheckSearch(true);

                renderTests(totalTest);
                setTests(totalTest);

                ReactSession.set("enableLoadingData", "no");
            } else {
                // setTests([]);
                showErrorToast(response.message);
            }
        });
    };

    const searchData = (val) => {
        let users = ReactSession.get("user");
        if (val !== users.email) {
            if (!isLoading && !isLoadMoreTestLoading) {
                setSearch(val);

                calculateTotalCount(formValues.methodology, val);
            } else {
                setSearch(val);
                getTests(false);
            }
        }
    };
    const showErrorToast = (error) => {
        toast(
            <ToastMessage
                type={"error"}
                message={error}
                closable={true}
                onClose={() => {
                    toast.dismiss();
                }}
            />,
            {
                className: "errortoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };

    const showSuccessToast = (message) => {
        toast(
            <ToastMessage
                type={"success"}
                message={message}
                closable={true}
                onClose={() => {
                    toast.dismiss();
                }}
            />,
            {
                className: "successtoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };
    useEffect(() => {
        // set time taken by test
        function tickTime() {
            if (isCheckSearch) {
                setCheckSearch(false);

                if (!isLoading && !isLoadMoreTestLoading && search) {
                    calculateTotalCount(formValues.methodology, search);
                }
            }
        }
        let timeData = setInterval(() => tickTime(), 1000);

        return () => clearInterval(timeData);
    }, [search, tests, isCheckSearch, isLoading, isLoadMoreTestLoading]);

    const updateNewFeature = () => {
        updateNewFeatureService(ReactSession.get("token")).then(
            (response) => {}
        );
    };
    const allowEditAccess = (test, index) => {
        let token = ReactSession.get("token");

        setRefreshLoading(test.test_id);

        allowEditAccessService({ test_id: test.test_id }, token).then(
            (response) => {
                setRefreshLoading(null);
                if (response.success) {
                    if (response.status === "open") {
                        navigate(`/r/test/${test.test_id}/welcome`);
                    } else if (response.status === "locked") {
                        setAllowEditAccessModal({
                            open: true,
                            user: response.locking_user_name,
                            test: response.test,
                        });

                        setTests((prevArray) => {
                            const newArray = [...prevArray];

                            let test = prevArray[index];

                            test.last_user_id = response.test.last_user_id;

                            test.last_edit_timestamp =
                                response.test.last_edit_timestamp;

                            newArray[index] = test;

                            return newArray;
                        });
                    }
                }
            }
        );
    };
    const renderTests = (testsData) => {
        var render = [];

        if (!isFilterLoading && !isLoading) {
            let filteredUsers = testsData
                .filter((data) => {
                    if (formValues.status && formValues.methodology) {
                        return (
                            data.status === formValues.status &&
                            data.methodology === formValues.methodology
                        );
                    } else if (formValues.status && !formValues.methodology) {
                        return data.status === formValues.status;
                    } else if (!formValues.status && formValues.methodology) {
                        return data.methodology === formValues.methodology;
                    } else {
                        return data;
                    }
                })
                .sort((a, b) => {
                    if (formValues.order === "asc") {
                        return (
                            new Date(a.activity_date) -
                            new Date(b.activity_date)
                        );
                    } else {
                        return (
                            new Date(b.activity_date) -
                            new Date(a.activity_date)
                        );
                    }
                });

            var checkSearchTestExistsCount = 0;
            filteredUsers.forEach(function (test, test_index) {
                var rgxp = new RegExp(search.toLowerCase(), "g");
                var searchTitle = test.test_name.toLowerCase();
                if (
                    searchTitle.match(rgxp) &&
                    searchTitle.match(rgxp).length > 0
                ) {
                    var status = test.status;
                    if (status === "published") {
                        var status = "live";
                    }

                    var averageTimeSpendMinute = 0;
                    var averageTimeSpendSeconds = 0;
                    if (test.test_results_count !== 0) {
                        var averageTimeSpendMinute = Math.floor(
                            test.total_time_spent /
                                (test.test_results_count * 60)
                        );
                        var averageTimeSpendSeconds = (
                            (test.total_time_spent / test.test_results_count) %
                            60
                        ).toFixed(0);
                    }

                    var repeat = (
                        <CardTemplate
                            className={
                                test && movingTestId == test.id.toString()
                                    ? "moving-test-animation"
                                    : ""
                            }
                            key={test.id}
                            testName={test.test_name}
                            status={status}
                            methodology={test.methodology}
                            isDuplicate={test.is_duplicate}
                            isReportedTester={
                                test.reported_tester.status === "to_review"
                                    ? true
                                    : false
                            }
                            countReportedTester={
                                test.reported_tester.totalSelectedParticipant
                            }
                            language={
                                test.language === "en" ? "English" : "Arabic"
                            }
                            createdOn={test.published_date}
                            timeLengthLabel={test.time_length_label}
                            endedOn={test.ended_on}
                            percentageComplete={Math.round(
                                (test.saved_step * 100) / 5
                            )}
                            participants={test.test_results_count}
                            participantsTotal={
                                test.participants ? test.participants : ""
                            }
                            averageTimeSpendMinute={
                                averageTimeSpendMinute + "m"
                            }
                            averageTimeSpendSeconds={
                                averageTimeSpendSeconds + "s"
                            }
                            navigateLink={
                                test.status === "draft"
                                    ? "/r/test/" + test.id + "/welcome"
                                    : "/r/results/" + test.id + "/overview"
                            }
                            endingInDay={
                                test.close_condition == "specific-date" ||
                                test.close_condition ==
                                    "specific-date-after-no-participants"
                                    ? getDaysLeft(test, true)
                                    : "--"
                            }
                            endingInText={
                                test.close_condition == "specific-date" ||
                                test.close_condition ==
                                    "specific-date-after-no-participants"
                                    ? getDaysLeft(test, false)
                                    : ""
                            }
                            duplicate={() => {
                                if (
                                    test &&
                                    test.target_group &&
                                    test.target_group.target_type !=
                                        "test-links"
                                ) {
                                    setDuplicateModal({
                                        ...duplicateModal,
                                        open: true,
                                        test_id: test.id,
                                        duplicate_preference: null,
                                    });
                                } else {
                                    duplicateTestApi(test.id);
                                }
                            }}
                            deleteTest={() => {
                                setConfirmModal({
                                    open: true,
                                    confirm_btn_title: "Delete",
                                    confirm_title: "Delete test",
                                    confirm_message:
                                        " Are you sure you want to delete this test?",
                                    test_id: test.id,
                                    confirmation_for: "testdelete",
                                    confirm_title_class: "text-danger",
                                });
                            }}
                            openCopyLink={() => {
                                setCopyLinkModal({
                                    open: true,
                                    test_id: test.id,
                                    password: test.password,
                                    test: test,
                                    link:
                                        process.env.REACT_APP_URL +
                                        "t/" +
                                        encryptId(test.id, test),
                                    resultLink:
                                        process.env.REACT_APP_URL +
                                        "v/results/" +
                                        encryptId(test.id, test) +
                                        "/overview",
                                });
                            }}
                            endTest={() => {
                                setConfirmModal({
                                    open: true,
                                    confirm_btn_title:
                                        test.methodology == "Survey"
                                            ? "End Survey"
                                            : "End Test",
                                    confirm_title:
                                        test.methodology == "Survey"
                                            ? "End this survey?"
                                            : "End this test?",
                                    confirm_message:
                                        test.methodology == "Survey"
                                            ? " By ending this survey, you’ll stop collecting results. But don’t worry, any unspent credits will be refunded to your account."
                                            : " By ending this test, you’ll stop collecting results. But don’t worry, any unspent credits will be refunded to your account.",
                                    test_id: test.id,
                                    confirmation_for: "endtest",
                                    confirm_title_class: "text-danger",
                                });
                            }}
                            editTest={() => {
                                if (test.status === "draft") {
                                    //navigate(`/r/test/${test.id}/welcome`);
                                    allowEditAccess(
                                        {
                                            test_id: test.id,
                                            workspace_id: test.workspace_id,
                                            workspace_project_id:
                                                test.workspace_project_id,
                                        },
                                        test_index
                                    );
                                } else {
                                    navigate(`/r/results/${test.id}/overview`);
                                }
                            }}
                            testSummary={() => {
                                setTestSummaryModal({
                                    open: true,
                                    test_id: test.id,
                                });
                                // window.open(`/researcher/tests/${test.id}/review`, '_blank', 'noopener,noreferrer');
                                //navigate();
                            }}
                            moveTest={() => {
                                setMoveTestModal({
                                    open: true,
                                    test_id: test.id,
                                    test_name: test.test_name,
                                    project_id: test.workspace_project_id,
                                    workspace_id: test.workspace_id,
                                });
                            }}
                            user={test.user ? test.user : null}
                            workspace_team_member={
                                test.workspace_team_member
                                    ? test.workspace_team_member
                                    : null
                            }
                            workspace_project_team_member={
                                test.workspace_project_team_member
                                    ? test.workspace_project_team_member
                                    : null
                            }
                            refreshLoading={
                                refreshLoading === test.id ? true : false
                            }
                            test={test}
                        />
                    );

                    render.push(repeat);

                    checkSearchTestExistsCount++;
                }
            });

            // check after search no test available
            if (
                search &&
                checkSearchTestExistsCount === 0 &&
                !isLoadMoreTestLoading
            ) {
                render.push(
                    <div className={`dashboard-search-no-data-found w-100`}>
                        <div className={`search-no-data-found`}>
                            <div className="search-no-data-inner">
                                <Icon value={"Search"} size={"extra-large"} />
                                <Text type="subtitle-2">
                                    {formValues.status
                                        ? `No result found in ${
                                              formValues.status === "published"
                                                  ? "live"
                                                  : formValues.status
                                          }!`
                                        : "No result found in all tests!"}
                                </Text>
                                <Text type="body-text-3">
                                    There is no result matching your search.
                                </Text>

                                {formValues.status ? (
                                    <Button
                                        size="small"
                                        iconLeft={
                                            <Icon
                                                size={"small"}
                                                value={"search"}
                                                colorClass={"gray-50-svg"}
                                            />
                                        }
                                        label={"Search in all tests"}
                                        onClick={() => {
                                            setFormValueData("");
                                            //console.log('clear search')
                                        }}
                                    />
                                ) : (
                                    <Button
                                        size="small"
                                        label={"Clear search"}
                                        onClick={() => {
                                            searchData("");
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                );
            }
        } else {
            [1, 2, 3, 4, 5, 6, 7, 8].forEach(function (index) {
                var repeat = (
                    <CardTemplate isLoading={isFilterLoading} key={index} />
                );

                render.push(repeat);
            });
        }

        if (!isFilterLoading && !isLoading) {
            //filterTestCount
            return <>{render}</>;
        } else {
            return render;
        }
    };

    const getDaysLeft = (test, endingInText) => {
        var daysdiff = moment
            .utc(test.closed_on)
            .local()
            .diff(moment(), "days");

        var hoursdiff = moment
            .utc(test.closed_on)
            .local()
            .diff(moment(), "hours");

        var minutesdiff = moment
            .utc(test.closed_on)
            .local()
            .diff(moment(), "minutes");

        if (daysdiff > 0) {
            if (endingInText) {
                return daysdiff;
            } else {
                return "days";
            }
        } else if (hoursdiff < 24 && hoursdiff > 0) {
            if (endingInText) {
                return hoursdiff;
            } else {
                return "hour";
            }
        } else if (minutesdiff < 60 && minutesdiff >= 0) {
            if (endingInText) {
                return minutesdiff;
            } else {
                return "minute";
            }
        } else {
            if (endingInText) {
                return "--";
            } else {
                return "";
            }
        }
    };

    const setFormValueData = (status) => {
        if (!isLoading && !isLoadMoreTestLoading) {
            setFormValues({ ...formValues, status: status, loading: false });
        } else {
            setFormValues({ ...formValues, status: status, loading: true });
        }
    };

    useEffect(() => {
        //console.log(formValues)
        if (formValues.loading) {
            //ReactSession.set("dashboardFilter", formValues); // set value in session
            setIsApplyFilter(true);
            getTests(false);
        }
    }, [formValues]);

    const closeNewTestModal = (reload) => {
        setOpenNewTestModal(false);
    };
    const closeChooseMethodologiesModal = (reload) => {
        setOpenChooseMethodologiesModal(false);
    };

    const calculateTotalCount = (methodology, searchKeyword) => {
        // calculation count
        var allCount = 0;
        var draftCount = 0;
        var publishedCount = 0;
        var completedCount = 0;
        let TestCountData = tests.filter((data) => {
            // search parameter
            var rgxp = new RegExp(searchKeyword.toLowerCase(), "g");
            var searchTitle = data.test_name.toLowerCase();

            // draft && complted & published count
            if (formValues.status && methodology) {
                if (
                    data.status === "draft" &&
                    data.methodology === methodology
                ) {
                    if (
                        searchTitle.match(rgxp) &&
                        searchTitle.match(rgxp).length > 0
                    ) {
                        draftCount++;
                    }
                }

                if (
                    data.status === "published" &&
                    data.methodology === methodology
                ) {
                    if (
                        searchTitle.match(rgxp) &&
                        searchTitle.match(rgxp).length > 0
                    ) {
                        publishedCount++;
                    }
                }

                if (
                    data.status === "completed" &&
                    data.methodology === methodology
                ) {
                    if (
                        searchTitle.match(rgxp) &&
                        searchTitle.match(rgxp).length > 0
                    ) {
                        completedCount++;
                    }
                }
            } else if (methodology && !formValues.status) {
                if (
                    data.status === "draft" &&
                    data.methodology === methodology
                ) {
                    if (
                        searchTitle.match(rgxp) &&
                        searchTitle.match(rgxp).length > 0
                    ) {
                        draftCount++;
                    }
                }

                if (
                    data.status === "published" &&
                    data.methodology === methodology
                ) {
                    if (
                        searchTitle.match(rgxp) &&
                        searchTitle.match(rgxp).length > 0
                    ) {
                        publishedCount++;
                    }
                }

                if (
                    data.status === "completed" &&
                    data.methodology === methodology
                ) {
                    if (
                        searchTitle.match(rgxp) &&
                        searchTitle.match(rgxp).length > 0
                    ) {
                        completedCount++;
                    }
                }
            } else if (!methodology && formValues.status) {
                if (data.status === "draft") {
                    if (
                        searchTitle.match(rgxp) &&
                        searchTitle.match(rgxp).length > 0
                    ) {
                        draftCount++;
                    }
                }

                if (data.status === "published") {
                    if (
                        searchTitle.match(rgxp) &&
                        searchTitle.match(rgxp).length > 0
                    ) {
                        publishedCount++;
                    }
                }

                if (data.status === "completed") {
                    if (
                        searchTitle.match(rgxp) &&
                        searchTitle.match(rgxp).length > 0
                    ) {
                        completedCount++;
                    }
                }
            } else {
                if (data.status === "draft") {
                    if (
                        searchTitle.match(rgxp) &&
                        searchTitle.match(rgxp).length > 0
                    ) {
                        draftCount++;
                    }
                }

                if (data.status === "published") {
                    if (
                        searchTitle.match(rgxp) &&
                        searchTitle.match(rgxp).length > 0
                    ) {
                        publishedCount++;
                    }
                }

                if (data.status === "completed") {
                    if (
                        searchTitle.match(rgxp) &&
                        searchTitle.match(rgxp).length > 0
                    ) {
                        completedCount++;
                    }
                }
            }
        });

        if (
            !isLoading &&
            !isLoadMoreTestLoading &&
            ReactSession.get("enableLoadingData") === "no"
        ) {
            setStatusTestsCount({
                all: draftCount + publishedCount + completedCount,
                draft: draftCount,
                published: publishedCount,
                completed: completedCount,
            });
        }
    };

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if (event.target.nodeName == "DIV") {
                        if (!search) {
                            setEnableSearch(false);
                        }
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref, search]);
    }

    useOutsideAlerter(searchRef);

    useOutsideAlerter(searchRef);

    useEffect(() => {
        if (enableSearch && searchInputRef.current) {
            searchInputRef.current.focus();
        }
    }, [enableSearch]);

    const searchInputRef = useRef(null);

    function useOutsideAlerterKey(ref) {
        useEffect(() => {
            function handleKeyDown(event) {
                if (event.key === "Escape") {
                    if (ref.current && document.activeElement !== ref.current) {
                        if (!search) {
                            setEnableSearch(false);
                        }
                        // else {
                        //     setSearch("");
                        // }
                    }
                }
            }

            // Bind the event listener
            document.addEventListener("keydown", handleKeyDown);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("keydown", handleKeyDown);
            };
        }, [ref, search]);
    }

    useOutsideAlerterKey(searchInputRef);

    const handleEscPress = (event) => {
        if (event.key === "Escape") {
            if (!search) {
                setEnableSearch(false);
            } else {
                setSearch("");
            }
        }
    };

    const frozenPaymentMethodUpdate = (status = null) => {
        let payment_method_modal = {
            open: true,
            workspace_id: workspaceid,
            status: status,
        };
        ReactSession.set("payment_method_modal", payment_method_modal);
        navigate(`/wsp/${workspaceid}/workspace-billing`);
    };
    const getTestPublishingFees = (methodology) => {
        let map_object = {
            "Prototype test": "publishing_credits_prototype_test",
            "Tree Test": "publishing_credits_tree_test",
            "Preference Test": "publishing_credits_preference_test",
            Survey: "publishing_credits_survey",
            "Card Sorting": "publishing_credits_card_sorting",
            "Five Seconds Test": "publishing_credits_five_seconds_test",
            "First click": "publishing_credits_first_click",
        };
        if (publishing_credits_meth[map_object[methodology]]) {
            return publishing_credits_meth[map_object[methodology]];
        }
        return 0;
    };

    function dropdownClickHandler() {
        setTestTypeClicked(true);
    }

    return (
        <LayoutResearcher
            isLoading={false}
            skipCheck={false}
            activeMenu={"dashboard"}
            openSupportPanel={show_support_panel}
            hideSupportPanel={() => {
                setShowSupportPanel(false);
            }}
            wrapClass={
                workspace &&
                workspace.status == "frozen" &&
                teamMember &&
                teamMember.role == "member"
                    ? "frozen-account"
                    : ""
            }
            isWorkspaceFrozen={
                workspace &&
                workspace.status == "frozen" &&
                teamMember &&
                teamMember.role == "member"
                    ? true
                    : false
            }
            openFrozenModal={openFrozenModal}
        >
            <Helmet>
                <title>
                    {workspace ? workspace.title : "Your dashboard"} | UserQ
                </title>
            </Helmet>
            <>
                {isLoading ? (
                    <div className="welcome-modal-loader mid-page-loader">
                        <div className="welcome-loader-inner mid-page-inner-loader">
                            <LoadingIcon />
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="das-hdsrch-hold">
                            {workspace && workspace.status === "frozen" ? (
                                <div className="mb-40">
                                    <ToastMessage
                                        type={
                                            teamMember &&
                                            teamMember.role == "member"
                                                ? "snowflake"
                                                : workspace &&
                                                  workspace.show_payment_method_saved_msg ==
                                                      1
                                                ? "info"
                                                : "error"
                                        }
                                        icon={
                                            teamMember &&
                                            teamMember.role == "member"
                                                ? "snowflake"
                                                : workspace &&
                                                  workspace.show_payment_method_saved_msg ==
                                                      1
                                                ? "time"
                                                : "error"
                                        }
                                        message={
                                            <>
                                                {teamMember && (
                                                    <>
                                                        {teamMember.role ===
                                                        "admin" ? (
                                                            <>
                                                                {workspace &&
                                                                workspace.show_payment_method_saved_msg ==
                                                                    1 ? (
                                                                    <>
                                                                        Your
                                                                        payment
                                                                        method
                                                                        has been
                                                                        successfully
                                                                        saved.
                                                                        The
                                                                        subscription
                                                                        payment
                                                                        will be
                                                                        processed
                                                                        soon.
                                                                    </>
                                                                ) : (
                                                                    <div className="d-flex align-items-center freez-toast-wrap">
                                                                        <span className="mr-3">
                                                                            This
                                                                            workspace
                                                                            is
                                                                            frozen
                                                                            due
                                                                            to
                                                                            unsuccessful
                                                                            transaction.
                                                                            Please
                                                                            update
                                                                            your
                                                                            payment
                                                                            method
                                                                            to
                                                                            retain
                                                                            team
                                                                            capabilities
                                                                            or
                                                                            switch
                                                                            to
                                                                            Pay-As-You-Go
                                                                        </span>
                                                                        <Button
                                                                            type={
                                                                                "primary"
                                                                            }
                                                                            size={
                                                                                "small"
                                                                            }
                                                                            label={
                                                                                "Update payment method"
                                                                            }
                                                                            onClick={(
                                                                                e
                                                                            ) => {
                                                                                e.stopPropagation();

                                                                                frozenPaymentMethodUpdate(
                                                                                    "frozen"
                                                                                );
                                                                            }}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                The workspace is
                                                                not available
                                                                due to pending
                                                                payment. Please
                                                                contact your
                                                                workspace admin
                                                                to resolve the
                                                                issue.
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        }
                                        closable={false}
                                    />
                                </div>
                            ) : (
                                <>
                                    {workspace &&
                                        workspace.payment_method &&
                                        workspace.payment_method
                                            .card_expire_status === 1 &&
                                        teamMember &&
                                        teamMember.role == "admin" && (
                                            <div className="mb-40">
                                                <ToastMessage
                                                    type={"warning"}
                                                    message={
                                                        <>
                                                            {workspace
                                                                .payment_method
                                                                .expired ? (
                                                                <>
                                                                    As the admin
                                                                    of this
                                                                    workspace,
                                                                    it's
                                                                    mandatory to
                                                                    maintain a
                                                                    valid
                                                                    payment
                                                                    method. The
                                                                    credit card
                                                                    connected to
                                                                    this
                                                                    workspace
                                                                    has expired
                                                                    on{" "}
                                                                    {(function () {
                                                                        if (
                                                                            workspace
                                                                                .payment_method
                                                                                .card_expire_at
                                                                        ) {
                                                                            return moment(
                                                                                workspace
                                                                                    .payment_method
                                                                                    .card_expire_at
                                                                            ).format(
                                                                                "DD/MM/YY"
                                                                            );
                                                                        } else {
                                                                            return "-";
                                                                        }
                                                                    })()}
                                                                    . The next
                                                                    billing date
                                                                    is scheduled
                                                                    for{" "}
                                                                    {workspace.next_billing_date
                                                                        ? moment(
                                                                              workspace.next_billing_date
                                                                          ).format(
                                                                              "DD/MM/YY"
                                                                          )
                                                                        : "-"}
                                                                    .<br />{" "}
                                                                    Please add
                                                                    or update
                                                                    your payment
                                                                    method to
                                                                    ensure it's
                                                                    in place
                                                                    before the
                                                                    next billing
                                                                    cycle.
                                                                </>
                                                            ) : (
                                                                <>
                                                                    As the admin
                                                                    of this
                                                                    workspace,
                                                                    it's
                                                                    mandatory to
                                                                    maintain a
                                                                    valid
                                                                    payment
                                                                    method. The
                                                                    credit card
                                                                    connected to
                                                                    this
                                                                    workspace
                                                                    will expire
                                                                    on{" "}
                                                                    {(function () {
                                                                        if (
                                                                            workspace
                                                                                .payment_method
                                                                                .card_expire_at
                                                                        ) {
                                                                            return moment(
                                                                                workspace
                                                                                    .payment_method
                                                                                    .card_expire_at
                                                                            ).format(
                                                                                "DD/MM/YY"
                                                                            );
                                                                        } else {
                                                                            return "-";
                                                                        }
                                                                    })()}
                                                                    . The next
                                                                    billing date
                                                                    is scheduled
                                                                    for{" "}
                                                                    {workspace.next_billing_date
                                                                        ? moment(
                                                                              workspace.next_billing_date
                                                                          ).format(
                                                                              "DD/MM/YY"
                                                                          )
                                                                        : "-"}
                                                                    .<br />{" "}
                                                                    Please add
                                                                    or update
                                                                    your payment
                                                                    method to
                                                                    ensure it's
                                                                    in place
                                                                    before the
                                                                    next billing
                                                                    cycle.
                                                                </>
                                                            )}
                                                            <Button
                                                                type={"primary"}
                                                                size={"small"}
                                                                label={
                                                                    "Update payment method"
                                                                }
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.stopPropagation();

                                                                    frozenPaymentMethodUpdate(
                                                                        "expired"
                                                                    );
                                                                }}
                                                            />
                                                        </>
                                                    }
                                                    closable={false}
                                                />
                                            </div>
                                        )}
                                </>
                            )}
                            <div className="page-heading-wrap page-heading-wrap d-flex  align-items-center justify-content-between dashboard-page-heading">
                                <div className="test-publish-fee-header-left align-items-center d-flex test-dashboard-title-left">
                                    {project && (
                                        <>
                                            <div className="back-to-dashboard">
                                                <Link
                                                    className="back-arrow-btn"
                                                    to={
                                                        "/wsp/" +
                                                        project.workspace_id +
                                                        "/"
                                                    }
                                                >
                                                    <Icon
                                                        value={"back arrow"}
                                                        colorClass={
                                                            "gray-700-svg"
                                                        }
                                                        hover={"true"}
                                                    />
                                                </Link>
                                            </div>
                                            <span className="folder-icon">
                                                <Icon
                                                    value={"folder"}
                                                    size={"medium"}
                                                    colorClass={"gray-700-svg"}
                                                />
                                            </span>
                                            <Text
                                                type={"h2"}
                                                cssClasses={`${
                                                    project &&
                                                    containsArabic(
                                                        project.title
                                                    )
                                                        ? "arabic-font"
                                                        : ""
                                                } `}
                                            >
                                                {project && project.title}
                                            </Text>
                                            {workspace &&
                                                workspace.type == "Team" && (
                                                    <div
                                                        className="mamber-added d-flex align-items-center"
                                                        onClick={() => {
                                                            setProjectMembersModal(
                                                                {
                                                                    ...projectMembersModal,
                                                                    open: true,
                                                                }
                                                            );
                                                        }}
                                                    >
                                                        <Icon
                                                            value={"two-user"}
                                                            colorClass={
                                                                "gray-700-svg"
                                                            }
                                                            hover={"true"}
                                                        />
                                                        <Text
                                                            type={"body-text-3"}
                                                            fontWeight={
                                                                "medium-font"
                                                            }
                                                        >
                                                            {
                                                                project
                                                                    .workspace_project_team_members
                                                                    .length
                                                            }{" "}
                                                        </Text>
                                                    </div>
                                                )}
                                            {workspace &&
                                                workspace.type ==
                                                    "Individual" && (
                                                    <div
                                                        className="mamber-added d-flex align-items-center"
                                                        onClick={() => {
                                                            setInviteMemberModal(
                                                                {
                                                                    ...inviteMemberModal,
                                                                    open: true,
                                                                }
                                                            );
                                                        }}
                                                    >
                                                        <Icon
                                                            value={"two-user"}
                                                            colorClass={
                                                                "gray-700-svg"
                                                            }
                                                            hover={"true"}
                                                        />
                                                    </div>
                                                )}
                                        </>
                                    )}

                                    {!isFilterLoading && !isLoading && (
                                        <>
                                            {workspace &&
                                                workspace.type ===
                                                    "Individual" && (
                                                    <>
                                                        {days_left > 0 && (
                                                            <Text
                                                                type={
                                                                    "body-text-3"
                                                                }
                                                                fontWeight={
                                                                    "medium-font"
                                                                }
                                                                cssClasses={
                                                                    "none-for-mobile"
                                                                }
                                                            >
                                                                {" "}
                                                                No publishing
                                                                fee
                                                            </Text>
                                                        )}

                                                        {days_left > 0 && (
                                                            <Tag
                                                                type="purple-tag"
                                                                iconLeft={
                                                                    "time"
                                                                }
                                                                colorClass="secondary-purple-200-svg path"
                                                                title={
                                                                    days_left +
                                                                    " day" +
                                                                    (days_left >
                                                                    1
                                                                        ? "s"
                                                                        : "") +
                                                                    " left"
                                                                }
                                                            />
                                                        )}
                                                    </>
                                                )}
                                        </>
                                    )}
                                </div>

                                {isPageRefresh ? (
                                    <div className="page-head-right d-flex dashbaord-header-right-with-search test-dashboard-search-right">
                                        <div
                                            className={
                                                "search-functionlity-data"
                                            }
                                        >
                                            <span
                                                className={`dashboard-search-icon skeleton-search`}
                                            >
                                                <Skeleton
                                                    width={40}
                                                    height={40}
                                                    style={{
                                                        borderRadius: "5px",
                                                    }}
                                                />
                                            </span>
                                        </div>
                                        <Skeleton
                                            width={230}
                                            height={60}
                                            style={{ borderRadius: "50px" }}
                                        />
                                    </div>
                                ) : isTestCount !== 0 ? (
                                    <div className="page-head-right d-flex dashbaord-header-right-with-search test-dashboard-search-right">
                                        <div className="search-icon-mobile">
                                            <span
                                                className={`dashboard-search-icon ${
                                                    !enableSearch
                                                        ? "disabled-search"
                                                        : "active"
                                                }`}
                                                onClick={toggleSearch}
                                            >
                                                <Icon
                                                    value={"search"}
                                                    size={"medium"}
                                                    colorClass={"gray-600-svg"}
                                                />
                                            </span>
                                        </div>
                                        <div
                                            className={
                                                "search-functionlity-data search-for-desktop"
                                            }
                                        >
                                            {!enableSearch && (
                                                <span
                                                    className={`dashboard-search-icon ${
                                                        !isLoading
                                                            ? "disabled-search"
                                                            : ""
                                                    }`}
                                                    onClick={() =>
                                                        !isLoading
                                                            ? setEnableSearch(
                                                                  true
                                                              )
                                                            : setEnableSearch(
                                                                  false
                                                              )
                                                    }
                                                >
                                                    <Icon
                                                        value={"search"}
                                                        size={"medium"}
                                                        colorClass={
                                                            "gray-600-svg"
                                                        }
                                                        hover={"true"}
                                                    />
                                                </span>
                                            )}
                                            <span
                                                className={`dashboard-search-data-wrap ${
                                                    enableSearch
                                                        ? "active-search-1"
                                                        : ""
                                                }`}
                                                ref={searchRef}
                                            >
                                                <Search
                                                    ref={searchInputRef}
                                                    size={"small"}
                                                    type="text"
                                                    placeholder="Search for study"
                                                    value={search}
                                                    onChange={(event) => {
                                                        setFormValues({
                                                            ...formValues,
                                                            loading:
                                                                !isLoading &&
                                                                !isLoadMoreTestLoading
                                                                    ? false
                                                                    : true,
                                                        });

                                                        // if(event.target.value){
                                                        //     setSearch(event.target.value);
                                                        // }else{
                                                        searchData(
                                                            event.target.value
                                                        );
                                                        // }
                                                    }}
                                                    onClear={(event) => {
                                                        searchData("");
                                                    }}
                                                    onBlur={() => {
                                                        if (!search) {
                                                            setEnableSearch(
                                                                false
                                                            );
                                                        }
                                                    }}
                                                    loading={false}
                                                    onKeyDown={(e) => {
                                                        handleEscPress(e);
                                                    }}
                                                />
                                            </span>
                                        </div>
                                        <Button
                                            size={"large"}
                                            cssclass={"create-test-btn"}
                                            iconLeft={
                                                <Icon
                                                    value={"add"}
                                                    colorClass={
                                                        "gray-50-svg add-icon add-icon-dashboard"
                                                    }
                                                />
                                            }
                                            label={"Create new test"}
                                            onClick={() => {
                                                setOpenChooseMethodologiesModal(
                                                    true
                                                );
                                            }}
                                        />
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                            <div className="search-field-form-mobile">
                                <div className={"search-functionlity-data"}>
                                    <span
                                        className={`dashboard-search-data-wrap ${
                                            enableSearch
                                                ? "active-search-1"
                                                : ""
                                        }`}
                                        ref={searchRef}
                                    >
                                        <Search
                                            size={"small"}
                                            type="text"
                                            placeholder="Search"
                                            value={search}
                                            onChange={(event) => {
                                                setFormValues({
                                                    ...formValues,
                                                    loading:
                                                        !isLoading &&
                                                        !isLoadMoreTestLoading
                                                            ? false
                                                            : true,
                                                });

                                                // if(event.target.value){
                                                //     setSearch(event.target.value);
                                                // }else{
                                                searchData(event.target.value);
                                                // }
                                            }}
                                            onClear={(event) => {
                                                searchData("");
                                            }}
                                            onBlur={() => {
                                                if (!search) {
                                                    setEnableSearch(false);
                                                }
                                            }}
                                            loading={false}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="dashboard-sub-head-wrap">
                            <a
                                onClick={(e) => {
                                    e.preventDefault();

                                    setShowSupportPanel(true);
                                }}
                                href={process.env.REACT_APP_URL + "t/support"}
                                target="_blank"
                                className="support-button"
                            >
                                <Icon
                                    value={"support"}
                                    colorClass={"gray-50-svg"}
                                    size={"medium"}
                                />
                                Support
                            </a>

                            {isPageRefresh ? (
                                <>
                                    <div
                                        id="test-filters"
                                        className={"dashboard-filter-wrap"}
                                    >
                                        <div className="dashboard-filter-left desktop-view">
                                            <div className="testnaviagtionlist desktop-view">
                                                <span
                                                    className={"filter-skelton"}
                                                >
                                                    <Skeleton
                                                        width={121}
                                                        height={46}
                                                        style={{
                                                            borderRadius: "5px",
                                                        }}
                                                    />
                                                </span>
                                                <span
                                                    className={"filter-skelton"}
                                                >
                                                    <Skeleton
                                                        width={121}
                                                        height={46}
                                                        style={{
                                                            borderRadius: "5px",
                                                        }}
                                                    />
                                                </span>
                                                <span
                                                    className={"filter-skelton"}
                                                >
                                                    <Skeleton
                                                        width={121}
                                                        height={46}
                                                        style={{
                                                            borderRadius: "5px",
                                                        }}
                                                    />
                                                </span>
                                                <span
                                                    className={"filter-skelton"}
                                                >
                                                    <Skeleton
                                                        width={121}
                                                        height={46}
                                                        style={{
                                                            borderRadius: "5px",
                                                        }}
                                                    />
                                                </span>
                                            </div>
                                        </div>

                                        <div className="dashboard-filter-right">
                                            <div
                                                className={
                                                    "dropdown mobile-view dropdown"
                                                }
                                            >
                                                <div className="filter-menu-wrap">
                                                    <span
                                                        className={`filter-menu-btn`}
                                                    >
                                                        <Skeleton
                                                            width={160}
                                                            height={46}
                                                            style={{
                                                                borderRadius:
                                                                    "5px",
                                                            }}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="filter-menu-wrap">
                                                <span
                                                    className={`filter-menu-btn`}
                                                >
                                                    <Skeleton
                                                        width={160}
                                                        height={46}
                                                        style={{
                                                            borderRadius: "5px",
                                                        }}
                                                    />
                                                </span>
                                            </div>
                                            <div className="dashboard-sorting-wrap">
                                                <span className="filter-sort-btn">
                                                    <Skeleton
                                                        width={160}
                                                        height={46}
                                                        style={{
                                                            borderRadius: "5px",
                                                        }}
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    {!isLoading && isTestCount !== 0 && (
                                        <div
                                            className="dashboard-filter-wrap"
                                            id="test-filters"
                                        >
                                            <div
                                                className={`dashboard-filter-left desktop-view`}
                                            >
                                                {testTypeClicked ? (
                                                    <>
                                                        {" "}
                                                        <div className="testnaviagtionlist desktop-view">
                                                            <span
                                                                className={
                                                                    "filter-skelton"
                                                                }
                                                            >
                                                                <Skeleton
                                                                    width={121}
                                                                    height={46}
                                                                    style={{
                                                                        borderRadius:
                                                                            "5px",
                                                                    }}
                                                                />
                                                            </span>
                                                            <span
                                                                className={
                                                                    "filter-skelton"
                                                                }
                                                            >
                                                                <Skeleton
                                                                    width={121}
                                                                    height={46}
                                                                    style={{
                                                                        borderRadius:
                                                                            "5px",
                                                                    }}
                                                                />
                                                            </span>
                                                            <span
                                                                className={
                                                                    "filter-skelton"
                                                                }
                                                            >
                                                                <Skeleton
                                                                    width={121}
                                                                    height={46}
                                                                    style={{
                                                                        borderRadius:
                                                                            "5px",
                                                                    }}
                                                                />
                                                            </span>
                                                            <span
                                                                className={
                                                                    "filter-skelton"
                                                                }
                                                            >
                                                                <Skeleton
                                                                    width={121}
                                                                    height={46}
                                                                    style={{
                                                                        borderRadius:
                                                                            "5px",
                                                                    }}
                                                                />
                                                            </span>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="testnaviagtionlist desktop-view">
                                                            <span
                                                                className={
                                                                    formValues.status ===
                                                                    ""
                                                                        ? "active"
                                                                        : ""
                                                                }
                                                                onClick={() => {
                                                                    setFormValueData(
                                                                        ""
                                                                    );
                                                                }}
                                                            >
                                                                All tests{" "}
                                                                <em className="tests-counter">
                                                                    (
                                                                    {
                                                                        statusTestsCount.all
                                                                    }
                                                                    )
                                                                </em>
                                                            </span>
                                                            <span
                                                                className={
                                                                    formValues.status ===
                                                                    "draft"
                                                                        ? "active"
                                                                        : ""
                                                                }
                                                                onClick={() => {
                                                                    setFormValueData(
                                                                        "draft"
                                                                    );
                                                                }}
                                                            >
                                                                Draft{" "}
                                                                <em className="tests-counter">
                                                                    (
                                                                    {
                                                                        statusTestsCount.draft
                                                                    }
                                                                    )
                                                                </em>
                                                            </span>
                                                            <span
                                                                className={
                                                                    formValues.status ===
                                                                    "published"
                                                                        ? "active"
                                                                        : ""
                                                                }
                                                                onClick={() => {
                                                                    setFormValueData(
                                                                        "published"
                                                                    );
                                                                }}
                                                            >
                                                                Live{" "}
                                                                <em className="tests-counter">
                                                                    (
                                                                    {
                                                                        statusTestsCount.published
                                                                    }
                                                                    )
                                                                </em>
                                                            </span>
                                                            <span
                                                                className={
                                                                    formValues.status ===
                                                                    "completed"
                                                                        ? "active"
                                                                        : ""
                                                                }
                                                                onClick={() => {
                                                                    setFormValueData(
                                                                        "completed"
                                                                    );
                                                                }}
                                                            >
                                                                Completed{" "}
                                                                <em className="tests-counter">
                                                                    (
                                                                    {
                                                                        statusTestsCount.completed
                                                                    }
                                                                    )
                                                                </em>
                                                            </span>
                                                        </div>
                                                    </>
                                                )}
                                            </div>

                                            <div className="dashboard-filter-right">
                                                {isLoadMoreTestLoading ? (
                                                    <>
                                                        <div className="filter-menu-wrap">
                                                            <Dropdown className="dropdown">
                                                                <Dropdown.Toggle className="dropdown-toggle">
                                                                    <span
                                                                        className={`filter-menu-btn ${
                                                                            toggleMethodology
                                                                                ? "active"
                                                                                : ""
                                                                        } `}
                                                                    >
                                                                        All
                                                                        methodologies
                                                                        <Icon
                                                                            colorClass={
                                                                                "chevron-icon"
                                                                            }
                                                                            value={
                                                                                "chevron"
                                                                            }
                                                                        />
                                                                    </span>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <div
                                                                        className={`hamburger-items`}
                                                                    >
                                                                        <Dropdown.Item
                                                                            className={`active-item`}
                                                                            onClick={
                                                                                dropdownClickHandler
                                                                            }
                                                                        >
                                                                            All
                                                                            methodologies
                                                                        </Dropdown.Item>

                                                                        <Dropdown.Item
                                                                            className={`active-item`}
                                                                            onClick={
                                                                                dropdownClickHandler
                                                                            }
                                                                        >
                                                                            Prototype
                                                                        </Dropdown.Item>

                                                                        <Dropdown.Item
                                                                            className={`active-item`}
                                                                            onClick={
                                                                                dropdownClickHandler
                                                                            }
                                                                        >
                                                                            Card
                                                                            sorting
                                                                        </Dropdown.Item>

                                                                        <Dropdown.Item
                                                                            className={`active-item ${
                                                                                formValues.methodology ===
                                                                                "Preference Test"
                                                                                    ? "active"
                                                                                    : ""
                                                                            }`}
                                                                            onClick={
                                                                                dropdownClickHandler
                                                                            }
                                                                        >
                                                                            Preference
                                                                            test
                                                                        </Dropdown.Item>

                                                                        <Dropdown.Item
                                                                            className={`active-item ${
                                                                                formValues.methodology ===
                                                                                "Tree Test"
                                                                                    ? "active"
                                                                                    : ""
                                                                            }`}
                                                                            onClick={
                                                                                dropdownClickHandler
                                                                            }
                                                                        >
                                                                            Tree
                                                                            test
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Item
                                                                            className={`active-item ${
                                                                                formValues.methodology ===
                                                                                "Survey"
                                                                                    ? "active"
                                                                                    : ""
                                                                            }`}
                                                                            onClick={
                                                                                dropdownClickHandler
                                                                            }
                                                                        >
                                                                            Survey
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Item
                                                                            className={`active-item  ${
                                                                                formValues.methodology ===
                                                                                "Five Seconds Test"
                                                                                    ? "active"
                                                                                    : ""
                                                                            }`}
                                                                            onClick={
                                                                                dropdownClickHandler
                                                                            }
                                                                        >
                                                                            5
                                                                            second
                                                                            test
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Item
                                                                            className={`active-item  ${
                                                                                formValues.methodology ===
                                                                                "First click"
                                                                                    ? "active"
                                                                                    : ""
                                                                            }`}
                                                                            onClick={
                                                                                dropdownClickHandler
                                                                            }
                                                                        >
                                                                            First
                                                                            click
                                                                        </Dropdown.Item>
                                                                    </div>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        {" "}
                                                        <div className="filter-menu-wrap">
                                                            <Dropdown className="dropdown">
                                                                <Dropdown.Toggle className="dropdown-toggle">
                                                                    <span
                                                                        className={`filter-menu-btn ${
                                                                            toggleMethodology
                                                                                ? "active"
                                                                                : ""
                                                                        } ${
                                                                            formValues.methodology !==
                                                                            ""
                                                                                ? "selected-filter"
                                                                                : ""
                                                                        }`}
                                                                    >
                                                                        {formValues.methodology ===
                                                                        ""
                                                                            ? "All methodologies"
                                                                            : methodologies[
                                                                                  formValues
                                                                                      .methodology
                                                                              ]}
                                                                        <Icon
                                                                            colorClass={
                                                                                "chevron-icon"
                                                                            }
                                                                            value={
                                                                                "chevron"
                                                                            }
                                                                        />
                                                                    </span>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <div
                                                                        className={`hamburger-items`}
                                                                    >
                                                                        <Dropdown.Item
                                                                            className={`active-item ${
                                                                                formValues.methodology ===
                                                                                ""
                                                                                    ? "active"
                                                                                    : ""
                                                                            }`}
                                                                            onClick={() => {
                                                                                calculateTotalCount(
                                                                                    "",
                                                                                    search
                                                                                );
                                                                                setFormValues(
                                                                                    {
                                                                                        ...formValues,
                                                                                        methodology:
                                                                                            "",
                                                                                        loading:
                                                                                            !isLoading &&
                                                                                            !isLoadMoreTestLoading
                                                                                                ? false
                                                                                                : true,
                                                                                    }
                                                                                );
                                                                            }}
                                                                        >
                                                                            All
                                                                            methodologies
                                                                        </Dropdown.Item>

                                                                        <Dropdown.Item
                                                                            className={`active-item ${
                                                                                formValues.methodology ===
                                                                                "Prototype test"
                                                                                    ? "active"
                                                                                    : ""
                                                                            }`}
                                                                            onClick={() => {
                                                                                calculateTotalCount(
                                                                                    "Prototype test",
                                                                                    search
                                                                                );
                                                                                setFormValues(
                                                                                    {
                                                                                        ...formValues,
                                                                                        methodology:
                                                                                            "Prototype test",
                                                                                        loading:
                                                                                            !isLoading &&
                                                                                            !isLoadMoreTestLoading
                                                                                                ? false
                                                                                                : true,
                                                                                    }
                                                                                );
                                                                            }}
                                                                        >
                                                                            Prototype
                                                                        </Dropdown.Item>

                                                                        <Dropdown.Item
                                                                            className={`active-item ${
                                                                                formValues.methodology ===
                                                                                "Card Sorting"
                                                                                    ? "active"
                                                                                    : ""
                                                                            }`}
                                                                            onClick={() => {
                                                                                calculateTotalCount(
                                                                                    "Card Sorting",
                                                                                    search
                                                                                );
                                                                                setFormValues(
                                                                                    {
                                                                                        ...formValues,
                                                                                        methodology:
                                                                                            "Card Sorting",
                                                                                        loading:
                                                                                            !isLoading &&
                                                                                            !isLoadMoreTestLoading
                                                                                                ? false
                                                                                                : true,
                                                                                    }
                                                                                );
                                                                            }}
                                                                        >
                                                                            Card
                                                                            sorting
                                                                        </Dropdown.Item>

                                                                        <Dropdown.Item
                                                                            className={`active-item ${
                                                                                formValues.methodology ===
                                                                                "Preference Test"
                                                                                    ? "active"
                                                                                    : ""
                                                                            }`}
                                                                            onClick={() => {
                                                                                calculateTotalCount(
                                                                                    "Preference Test",
                                                                                    search
                                                                                );
                                                                                setFormValues(
                                                                                    {
                                                                                        ...formValues,
                                                                                        methodology:
                                                                                            "Preference Test",
                                                                                        loading:
                                                                                            !isLoading &&
                                                                                            !isLoadMoreTestLoading
                                                                                                ? false
                                                                                                : true,
                                                                                    }
                                                                                );
                                                                            }}
                                                                        >
                                                                            Preference
                                                                            test
                                                                        </Dropdown.Item>

                                                                        <Dropdown.Item
                                                                            className={`active-item ${
                                                                                formValues.methodology ===
                                                                                "Tree Test"
                                                                                    ? "active"
                                                                                    : ""
                                                                            }`}
                                                                            onClick={() => {
                                                                                calculateTotalCount(
                                                                                    "Tree Test",
                                                                                    search
                                                                                );
                                                                                setFormValues(
                                                                                    {
                                                                                        ...formValues,
                                                                                        methodology:
                                                                                            "Tree Test",
                                                                                        loading:
                                                                                            !isLoading &&
                                                                                            !isLoadMoreTestLoading
                                                                                                ? false
                                                                                                : true,
                                                                                    }
                                                                                );
                                                                            }}
                                                                        >
                                                                            Tree
                                                                            test
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Item
                                                                            className={`active-item ${
                                                                                formValues.methodology ===
                                                                                "Survey"
                                                                                    ? "active"
                                                                                    : ""
                                                                            }`}
                                                                            onClick={() => {
                                                                                calculateTotalCount(
                                                                                    "Survey",
                                                                                    search
                                                                                );
                                                                                setFormValues(
                                                                                    {
                                                                                        ...formValues,
                                                                                        methodology:
                                                                                            "Survey",
                                                                                        loading:
                                                                                            !isLoading &&
                                                                                            !isLoadMoreTestLoading
                                                                                                ? false
                                                                                                : true,
                                                                                    }
                                                                                );
                                                                            }}
                                                                        >
                                                                            Survey
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Item
                                                                            className={`active-item  ${
                                                                                formValues.methodology ===
                                                                                "Five Seconds Test"
                                                                                    ? "active"
                                                                                    : ""
                                                                            }`}
                                                                            onClick={() => {
                                                                                calculateTotalCount(
                                                                                    "Five Seconds Test",
                                                                                    search
                                                                                );
                                                                                setFormValues(
                                                                                    {
                                                                                        ...formValues,
                                                                                        methodology:
                                                                                            "Five Seconds Test",
                                                                                        loading:
                                                                                            !isLoading &&
                                                                                            !isLoadMoreTestLoading
                                                                                                ? false
                                                                                                : true,
                                                                                    }
                                                                                );
                                                                            }}
                                                                        >
                                                                            5
                                                                            second
                                                                            test
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Item
                                                                            className={`active-item  ${
                                                                                formValues.methodology ===
                                                                                "First click"
                                                                                    ? "active"
                                                                                    : ""
                                                                            }`}
                                                                            onClick={() => {
                                                                                calculateTotalCount(
                                                                                    "First click",
                                                                                    search
                                                                                );
                                                                                setFormValues(
                                                                                    {
                                                                                        ...formValues,
                                                                                        methodology:
                                                                                            "First click",
                                                                                        loading:
                                                                                            !isLoading &&
                                                                                            !isLoadMoreTestLoading
                                                                                                ? false
                                                                                                : true,
                                                                                    }
                                                                                );
                                                                            }}
                                                                        >
                                                                            First
                                                                            click
                                                                        </Dropdown.Item>
                                                                    </div>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </>
                                                )}

                                                {testTypeClicked ? (
                                                    <>
                                                        <div className="testnaviagtionlist none-for-desktop">
                                                            <span
                                                                className={
                                                                    "filter-skelton mt-8"
                                                                }
                                                            >
                                                                <Skeleton
                                                                    width={70}
                                                                    height={20}
                                                                    style={{
                                                                        borderRadius:
                                                                            "5px",
                                                                    }}
                                                                />
                                                            </span>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Dropdown className="dropdown mobile-view">
                                                            <Dropdown.Toggle className="dropdown-toggle">
                                                                <span
                                                                    className={`filter-menu-btn`}
                                                                >
                                                                    {formValues.status ===
                                                                    ""
                                                                        ? "All tests"
                                                                        : formValues.status ===
                                                                          "published"
                                                                        ? "live"
                                                                        : formValues.status}
                                                                    {formValues.status ===
                                                                    "" ? (
                                                                        <em className="tests-counter">
                                                                            (
                                                                            {
                                                                                statusTestsCount.all
                                                                            }
                                                                            )
                                                                        </em>
                                                                    ) : (
                                                                        <em className="tests-counter">
                                                                            (
                                                                            {
                                                                                statusTestsCount[
                                                                                    formValues
                                                                                        .status
                                                                                ]
                                                                            }
                                                                            )
                                                                        </em>
                                                                    )}

                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="32"
                                                                        height="32"
                                                                        viewBox="0 0 32 32"
                                                                        fill="none"
                                                                    >
                                                                        <path
                                                                            d="M26 11L16 21L6 11"
                                                                            stroke="black"
                                                                            stroke-width="1.5"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                        />
                                                                    </svg>
                                                                </span>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <div
                                                                    className={`hamburger-items`}
                                                                >
                                                                    <Dropdown.Item
                                                                        className={`active-item ${
                                                                            formValues.status ===
                                                                            ""
                                                                                ? "active"
                                                                                : ""
                                                                        }`}
                                                                        onClick={() => {
                                                                            setFormValueData(
                                                                                ""
                                                                            );
                                                                        }}
                                                                    >
                                                                        All
                                                                        tests{" "}
                                                                        <em className="tests-counter">
                                                                            (
                                                                            {
                                                                                statusTestsCount.all
                                                                            }
                                                                            )
                                                                        </em>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item
                                                                        className={`active-item ${
                                                                            formValues.status ===
                                                                            "draft"
                                                                                ? "active"
                                                                                : ""
                                                                        }`}
                                                                        onClick={() => {
                                                                            setFormValueData(
                                                                                "draft"
                                                                            );
                                                                        }}
                                                                    >
                                                                        Draft{" "}
                                                                        <em className="tests-counter">
                                                                            (
                                                                            {
                                                                                statusTestsCount.draft
                                                                            }
                                                                            )
                                                                        </em>
                                                                    </Dropdown.Item>

                                                                    <Dropdown.Item
                                                                        className={`active-item ${
                                                                            formValues.status ===
                                                                            "published"
                                                                                ? "active"
                                                                                : ""
                                                                        }`}
                                                                        onClick={() => {
                                                                            setFormValueData(
                                                                                "published"
                                                                            );
                                                                        }}
                                                                    >
                                                                        Live{" "}
                                                                        <em className="tests-counter">
                                                                            (
                                                                            {
                                                                                statusTestsCount.published
                                                                            }
                                                                            )
                                                                        </em>
                                                                    </Dropdown.Item>

                                                                    <Dropdown.Item
                                                                        className={`active-item ${
                                                                            formValues.status ===
                                                                            "completed"
                                                                                ? "active"
                                                                                : ""
                                                                        }`}
                                                                        onClick={() => {
                                                                            setFormValueData(
                                                                                "completed"
                                                                            );
                                                                        }}
                                                                    >
                                                                        Completed{" "}
                                                                        <em className="tests-counter">
                                                                            (
                                                                            {
                                                                                statusTestsCount.completed
                                                                            }
                                                                            )
                                                                        </em>
                                                                    </Dropdown.Item>
                                                                </div>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </>
                                                )}

                                                <div className="dashboard-sorting-wrap">
                                                    <span
                                                        className="filter-sort-btn"
                                                        onClick={() => {
                                                            if (
                                                                !isLoading &&
                                                                !isLoadMoreTestLoading
                                                            ) {
                                                                if (
                                                                    formValues.order ===
                                                                    "desc"
                                                                ) {
                                                                    setFormValues(
                                                                        {
                                                                            ...formValues,
                                                                            order: "asc",
                                                                            loading: false,
                                                                        }
                                                                    );
                                                                } else {
                                                                    setFormValues(
                                                                        {
                                                                            ...formValues,
                                                                            order: "desc",
                                                                            loading: false,
                                                                        }
                                                                    );
                                                                }
                                                            }
                                                        }}
                                                    >
                                                        {formValues.order ===
                                                        "desc" ? (
                                                            <>
                                                                <span className="desktop-view">
                                                                    Newest to
                                                                    oldest
                                                                </span>
                                                                <Icon
                                                                    value={
                                                                        "sort-order-up"
                                                                    }
                                                                    colorClass={
                                                                        "gray-900-svg dashboard-sort"
                                                                    }
                                                                />
                                                            </>
                                                        ) : (
                                                            <>
                                                                <span className="desktop-view">
                                                                    Oldest to
                                                                    newest
                                                                </span>
                                                                <Icon
                                                                    value={
                                                                        "sort-order"
                                                                    }
                                                                    colorClass={
                                                                        "gray-900-svg dashboard-sort"
                                                                    }
                                                                />
                                                            </>
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                            {!isLoading &&
                                !isFilterLoading &&
                                isTestCount === 0 && (
                                    <>
                                        <div className="publish-fees-required-wrap d-flex align-items-center flex-wrap">
                                            <Text
                                                type={"h3"}
                                                children={
                                                    "Get started by choosing a test type"
                                                }
                                            />
                                            {workspace &&
                                                workspace.type ===
                                                    "Individual" && (
                                                    <>
                                                        {publishing_credits >
                                                            0 && (
                                                            <Tag
                                                                type="pink-tag"
                                                                iconLeft={
                                                                    "credit"
                                                                }
                                                                title={
                                                                    "Publishing fee: " +
                                                                    roundNumber(
                                                                        publishing_credits,
                                                                        2
                                                                    ) +
                                                                    " credits"
                                                                }
                                                            />
                                                        )}
                                                    </>
                                                )}
                                        </div>
                                        <Text
                                            type={"body-text-2"}
                                            fontWeight={"medium-font"}
                                            cssClasses={
                                                "gray-color mt-16 pricing-info-text"
                                            }
                                        >
                                            Share the test with your own
                                            participants and no recruitment fees
                                            apply.{" "}
                                            <UQLink
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                }}
                                                className={"link-text"}
                                                target="_blank"
                                                url={
                                                    "https://userq.com/pricing/"
                                                }
                                            >
                                                {" "}
                                                Learn more about pricing here.
                                            </UQLink>
                                        </Text>
                                    </>
                                )}
                        </div>
                        {isLoading ? (
                            <>
                                <div className="all-test-listing-wrapper">
                                    {renderTests([])}
                                </div>
                            </>
                        ) : (
                            <>
                                {isFilterLoading ? (
                                    <>
                                        <div className="all-test-listing-wrapper">
                                            {renderTests([])}
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {tests && tests.length > 0 ? (
                                            <>
                                                <div
                                                    className={
                                                        "cards-min-height"
                                                    }
                                                >
                                                    <div className="all-test-listing-wrapper">
                                                        {renderTests(tests)}

                                                        {isLoadMoreTestLoading &&
                                                            [
                                                                1, 2, 3, 4, 5,
                                                                6, 7, 8,
                                                            ].map(function (
                                                                index
                                                            ) {
                                                                return (
                                                                    <CardTemplate
                                                                        isLoading={
                                                                            true
                                                                        }
                                                                        key={
                                                                            index
                                                                        }
                                                                    />
                                                                );
                                                            })}
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                {/*{!isTestAvailable ?*/}

                                                {isTestCount === 0 ? (
                                                    <div className="creat-test-cards-wrapper">
                                                        {testDefaultCardsData.map(
                                                            (items, index) => {
                                                                return (
                                                                    <Defaultcard
                                                                        key={
                                                                            index
                                                                        }
                                                                        title={
                                                                            items.title
                                                                        }
                                                                        description={
                                                                            items.description
                                                                        }
                                                                        image={
                                                                            items.image
                                                                        }
                                                                        isComingSoon={
                                                                            items.isComingSoon
                                                                        }
                                                                        publishing_credits={
                                                                            workspace &&
                                                                            workspace.type ===
                                                                                "Individual"
                                                                                ? getTestPublishingFees(
                                                                                      items.methodology
                                                                                  )
                                                                                : 0
                                                                        }
                                                                        onClick={() => {
                                                                            if (
                                                                                items.methodology ===
                                                                                "Preference Test"
                                                                            ) {
                                                                                setTotalTests(
                                                                                    totalTestsCount.Preference_Test_count +
                                                                                        1
                                                                                );
                                                                            } else if (
                                                                                items.methodology ===
                                                                                "Card Sorting"
                                                                            ) {
                                                                                setTotalTests(
                                                                                    totalTestsCount.Card_Sorting_count +
                                                                                        1
                                                                                );
                                                                            } else if (
                                                                                items.methodology ===
                                                                                "Tree Test"
                                                                            ) {
                                                                                setTotalTests(
                                                                                    totalTestsCount.Tree_Test_test +
                                                                                        1
                                                                                );
                                                                            } else if (
                                                                                items.methodology ===
                                                                                "Survey"
                                                                            ) {
                                                                                setTotalTests(
                                                                                    totalTestsCount.Survey_count +
                                                                                        1
                                                                                );
                                                                            } else if (
                                                                                items.methodology ===
                                                                                "Five Seconds Test"
                                                                            ) {
                                                                                setTotalTests(
                                                                                    totalTestsCount.Five_Seconds_Test_count +
                                                                                        1
                                                                                );
                                                                            } else if (
                                                                                items.methodology ===
                                                                                "First click"
                                                                            ) {
                                                                                setTotalTests(
                                                                                    totalTestsCount.First_Click_count +
                                                                                        1
                                                                                );
                                                                            } else if (
                                                                                items.methodology ===
                                                                                "Prototype test"
                                                                            ) {
                                                                                setTotalTests(
                                                                                    totalTestsCount.prototype_test_count +
                                                                                        1
                                                                                );
                                                                            }
                                                                            setOpenNewTestModal(
                                                                                true
                                                                            );
                                                                            setModalMethodology(
                                                                                items.methodology
                                                                            );
                                                                        }}
                                                                    />
                                                                );
                                                            }
                                                        )}
                                                    </div>
                                                ) : (
                                                    <div
                                                        className={
                                                            "no-test-data-available"
                                                        }
                                                    >
                                                        No test available
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </>
                )}
            </>
            {workspace && (
                <WelcomeMessageModal
                    open={welcomeMessageModal.open}
                    close={() => {
                        setWelcomeMessageModal({
                            ...welcomeMessageModal,
                            open: false,
                        });
                        let user = ReactSession.get("user");
                        user.welcome_message_shown = 1;
                        ReactSession.set("user", user);
                        updateWelcomeMessage(ReactSession.get("token")).then(
                            (response) => {}
                        );

                        updateWorkspaceSuccessMessage(
                            { workspace_id: workspace.id },
                            ReactSession.get("token")
                        ).then((response) => {});
                    }}
                    body={
                        <>
                            {workspace.type === "Team" ? (
                                <div className="modal-workspace-welcom-info">
                                    <div className="userworkspace-name-wrap">
                                        {workspace && (
                                            <>
                                                <span className="user-workspace-icon">
                                                    <Icon
                                                        size={"medium"}
                                                        value={"group"}
                                                        colorClass={
                                                            "gray-50-svg"
                                                        }
                                                    />
                                                </span>
                                                <div className="user-workspace-info">
                                                    <Text
                                                        type={"body-text-2"}
                                                        fontWeight={
                                                            "medium-font"
                                                        }
                                                    >
                                                        {workspace.title}
                                                    </Text>
                                                    <Text
                                                        type={"caption"}
                                                        fontWeight={
                                                            "medium-font"
                                                        }
                                                        cssClasses={
                                                            "gray-color"
                                                        }
                                                    >
                                                        {workspace.type}{" "}
                                                        workspace
                                                    </Text>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    {teamMembers.length > 0 && (
                                        <>
                                            <Text
                                                type={"body-text-3"}
                                                fontWeight={"medium-font"}
                                                cssClasses={"gray-color"}
                                            >
                                                We’ve also invited your team to
                                                join the workspace.
                                                <br /> As soon as they accept
                                                the invitation, they will appear
                                                in the top bar.
                                            </Text>
                                        </>
                                    )}
                                    {teamMembers.length === 0 && (
                                        <>
                                            <Text
                                                type={"body-text-3"}
                                                fontWeight={"medium-font"}
                                            >
                                                You haven’t added any team
                                                member to this workspace yet.
                                                <br /> With the Team Plan, you
                                                can add up to 10 members for
                                                free.
                                            </Text>
                                            <div className="invite-team-link mt-32">
                                                <Link
                                                    to={`/wsp/${workspaceid}/team-&-settings`}
                                                    className="link-text"
                                                >
                                                    Invite team members
                                                </Link>
                                            </div>
                                        </>
                                    )}

                                    <div className="teamplan-team-list mt-20">
                                        {teamMembers.map((user, index) => {
                                            let backgroundColor = user.color;

                                            if (
                                                !backgroundColor ||
                                                user.status == "invitation_sent"
                                            ) {
                                                backgroundColor = "#CCCCCC"; // Default color
                                            }
                                            return (
                                                <CircularNameIcon
                                                    tooltip={
                                                        user && user.email
                                                            ? user.email
                                                            : ""
                                                    }
                                                    style={{ backgroundColor }}
                                                    key={index}
                                                    label={
                                                        user && user.name
                                                            ? user.name &&
                                                              user.name
                                                                  .charAt(0)
                                                                  .toUpperCase()
                                                            : user.email
                                                                  .charAt(0)
                                                                  .toUpperCase()
                                                    }
                                                />
                                            );
                                        })}
                                    </div>

                                    <div className="mt-20 mb-0">
                                        <Button
                                            type={"primary"}
                                            size={"large"}
                                            label={"Go to dashboard"}
                                            onClick={() => {
                                                setWelcomeMessageModal({
                                                    ...welcomeMessageModal,
                                                    open: false,
                                                });
                                                let user =
                                                    ReactSession.get("user");
                                                user.welcome_message_shown = 1;
                                                ReactSession.set("user", user);
                                                updateWelcomeMessage(
                                                    ReactSession.get("token")
                                                ).then((response) => {});
                                                updateWorkspaceSuccessMessage(
                                                    {
                                                        workspace_id:
                                                            workspace.id,
                                                    },
                                                    ReactSession.get("token")
                                                ).then((response) => {});
                                            }}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className="modal-workspace-welcom-info">
                                    <div className="userworkspace-name-wrap">
                                        {workspace && (
                                            <>
                                                <span className="user-workspace-icon">
                                                    <Icon
                                                        size={"medium"}
                                                        value={"user"}
                                                        colorClass={
                                                            "gray-50-svg"
                                                        }
                                                    />
                                                </span>
                                                <div className="user-workspace-info">
                                                    <Text
                                                        type={"body-text-2"}
                                                        fontWeight={
                                                            "medium-font"
                                                        }
                                                    >
                                                        {workspace.title}
                                                    </Text>
                                                    <Text
                                                        type={"caption"}
                                                        fontWeight={
                                                            "medium-font"
                                                        }
                                                        cssClasses={
                                                            "gray-color"
                                                        }
                                                    >
                                                        {workspace.type}{" "}
                                                        workspace
                                                    </Text>
                                                </div>
                                            </>
                                        )}
                                    </div>

                                    <Text
                                        type={"body-text-3"}
                                        fontWeight={"medium-font"}
                                        cssClasses={"gray-color"}
                                    >
                                        You can start running tests straight
                                        away.
                                        <br /> Remember, you only need to pay
                                        for what you use, and there
                                        <br /> are no publishing fees for the
                                        first 30 days.
                                    </Text>
                                    <div className="mt-20 mb-20">
                                        <Button
                                            type={"primary"}
                                            size={"large"}
                                            label={"Go to dashboard"}
                                            onClick={() => {
                                                setWelcomeMessageModal({
                                                    ...welcomeMessageModal,
                                                    open: false,
                                                });
                                                let user =
                                                    ReactSession.get("user");
                                                user.welcome_message_shown = 1;
                                                ReactSession.set("user", user);
                                                updateWelcomeMessage(
                                                    ReactSession.get("token")
                                                ).then((response) => {});
                                                updateWorkspaceSuccessMessage(
                                                    {
                                                        workspace_id:
                                                            workspace.id,
                                                    },
                                                    ReactSession.get("token")
                                                ).then((response) => {});
                                            }}
                                        />
                                    </div>
                                    <Link
                                        to={
                                            "/wsp/" +
                                            workspace.id +
                                            "/upgrade-workspace"
                                        }
                                        className="link-text"
                                    >
                                        Upgrade to Team plan for instant
                                        collaboration
                                    </Link>
                                </div>
                            )}
                        </>
                    }
                />
            )}

            {workspace && (
                <>
                    <WelcomeCongratsModal
                        open={openCongratsModal.open}
                        cssClass={"new-workspace-active-modal"}
                        close={() => {
                            setOpenCongratsModal({
                                open: false,
                                type: null,
                            });
                            updateWorkspaceSuccessMessage(
                                { workspace_id: workspace.id },
                                ReactSession.get("token")
                            ).then((response) => {});
                        }}
                        body={
                            <>
                                {openCongratsModal.type === "upgrade" ? (
                                    <div className="active-plan-modal-data">
                                        <div className="active-plan-icon-hold mb-20">
                                            <span className="active-plan-icon-wrap">
                                                <Icon
                                                    value={"group"}
                                                    size={"large"}
                                                    colorClass={"gray-50-svg"}
                                                />
                                            </span>
                                        </div>
                                        <Text type={"h1"}>
                                            {
                                                "Your workspace has been upgraded to Team plan"
                                            }
                                        </Text>

                                        <div className="active-plan-modal-mid-data mt-32">
                                            {teamMembers.length > 0 && (
                                                <>
                                                    <Text
                                                        type={"body-text-3"}
                                                        fontWeight={
                                                            "medium-font"
                                                        }
                                                    >
                                                        An invitation has been
                                                        sent to your team to
                                                        join the workspace.
                                                        <br /> As soon as they
                                                        accept the invitation,
                                                        they will appear in the
                                                        top bar.
                                                    </Text>
                                                </>
                                            )}

                                            {teamMembers.length === 0 && (
                                                <>
                                                    <Text
                                                        type={"body-text-3"}
                                                        fontWeight={
                                                            "medium-font"
                                                        }
                                                    >
                                                        You haven’t added any
                                                        team member to this
                                                        workspace yet.
                                                        <br /> With the Team
                                                        Plan, you can add up to
                                                        10 members for free.
                                                    </Text>
                                                    <div className="invite-team-link mt-32">
                                                        <Link
                                                            to={`/wsp/${workspaceid}/team-&-settings`}
                                                            className="link-text"
                                                        >
                                                            Invite team members
                                                        </Link>
                                                    </div>
                                                </>
                                            )}
                                        </div>

                                        <div className="teamplan-team-list mt-20">
                                            {teamMembers.map((user, index) => {
                                                let backgroundColor =
                                                    user.color;

                                                if (
                                                    !backgroundColor ||
                                                    user.status ==
                                                        "invitation_sent"
                                                ) {
                                                    backgroundColor = "#9C9C9C"; // Default color
                                                }
                                                return (
                                                    <CircularNameIcon
                                                        tooltip={
                                                            user && user.email
                                                                ? user.email
                                                                : ""
                                                        }
                                                        style={{
                                                            backgroundColor,
                                                        }}
                                                        key={index}
                                                        label={
                                                            user && user.name
                                                                ? user.name &&
                                                                  user.name
                                                                      .charAt(0)
                                                                      .toUpperCase()
                                                                : user.email
                                                                      .charAt(0)
                                                                      .toUpperCase()
                                                        }
                                                    />
                                                );
                                            })}
                                        </div>

                                        <div className="active-plan-modal-btn mt-32 mb-0">
                                            <Button
                                                type={"primary"}
                                                size={"large"}
                                                label={"Go to dashboard"}
                                                onClick={() => {
                                                    //closeIndividualWelcomeModal(false);
                                                    //navigate(`/wsp/${workspaceid}`);
                                                    setOpenCongratsModal({
                                                        open: false,
                                                        type: null,
                                                    });
                                                    updateWorkspaceSuccessMessage(
                                                        {
                                                            workspace_id:
                                                                workspace.id,
                                                        },
                                                        ReactSession.get(
                                                            "token"
                                                        )
                                                    ).then((response) => {});
                                                }}
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        {workspace.type === "Team" ? (
                                            <div className="active-plan-modal-data">
                                                <div className="active-plan-icon-hold mb-20">
                                                    <span className="active-plan-icon-wrap">
                                                        <Icon
                                                            value={"group"}
                                                            size={"large"}
                                                            colorClass={
                                                                "gray-50-svg"
                                                            }
                                                        />
                                                    </span>
                                                </div>
                                                <Text type={"h1"}>
                                                    {
                                                        "Your Team workspace is active"
                                                    }
                                                </Text>

                                                <div className="active-plan-modal-mid-data mt-32">
                                                    {teamMembers.length > 0 && (
                                                        <>
                                                            <Text
                                                                type={
                                                                    "body-text-3"
                                                                }
                                                                fontWeight={
                                                                    "medium-font"
                                                                }
                                                            >
                                                                An invitation
                                                                has been sent to
                                                                your team to
                                                                join the
                                                                workspace.
                                                                <br /> As soon
                                                                as they accept
                                                                the invitation,
                                                                they will appear
                                                                in the top bar.
                                                            </Text>
                                                        </>
                                                    )}

                                                    {teamMembers.length ===
                                                        0 && (
                                                        <>
                                                            <Text
                                                                type={
                                                                    "body-text-3"
                                                                }
                                                                fontWeight={
                                                                    "medium-font"
                                                                }
                                                            >
                                                                You haven’t
                                                                added any team
                                                                member to this
                                                                workspace yet.
                                                                <br /> With the
                                                                Team Plan, you
                                                                can add up to 10
                                                                members for
                                                                free.
                                                            </Text>
                                                            <div className="invite-team-link mt-32">
                                                                <Link
                                                                    to={`/wsp/${workspaceid}/team-&-settings`}
                                                                    className="link-text"
                                                                >
                                                                    Invite team
                                                                    members
                                                                </Link>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>

                                                <div className="teamplan-team-list mt-20">
                                                    {teamMembers.map(
                                                        (user, index) => {
                                                            let backgroundColor =
                                                                user.color;

                                                            if (
                                                                !backgroundColor ||
                                                                user.status ==
                                                                    "invitation_sent"
                                                            ) {
                                                                backgroundColor =
                                                                    "#9C9C9C"; // Default color
                                                            }
                                                            return (
                                                                <CircularNameIcon
                                                                    tooltip={
                                                                        user &&
                                                                        user.email
                                                                            ? user.email
                                                                            : ""
                                                                    }
                                                                    style={{
                                                                        backgroundColor,
                                                                    }}
                                                                    key={index}
                                                                    label={
                                                                        user &&
                                                                        user.name
                                                                            ? user.name &&
                                                                              user.name
                                                                                  .charAt(
                                                                                      0
                                                                                  )
                                                                                  .toUpperCase()
                                                                            : user.email
                                                                                  .charAt(
                                                                                      0
                                                                                  )
                                                                                  .toUpperCase()
                                                                    }
                                                                />
                                                            );
                                                        }
                                                    )}
                                                </div>

                                                <div className="active-plan-modal-btn mt-32 mb-0">
                                                    <Button
                                                        type={"primary"}
                                                        size={"large"}
                                                        label={
                                                            "Go to dashboard"
                                                        }
                                                        onClick={() => {
                                                            //closeIndividualWelcomeModal(false);
                                                            //navigate(`/wsp/${workspaceid}`);
                                                            setOpenCongratsModal(
                                                                {
                                                                    open: false,
                                                                    type: null,
                                                                }
                                                            );
                                                            updateWorkspaceSuccessMessage(
                                                                {
                                                                    workspace_id:
                                                                        workspace.id,
                                                                },
                                                                ReactSession.get(
                                                                    "token"
                                                                )
                                                            ).then(
                                                                (response) => {}
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="active-plan-modal-data">
                                                <div className="active-plan-icon-hold mb-20">
                                                    <span className="active-plan-icon-wrap">
                                                        <Icon
                                                            value={"user"}
                                                            size={"large"}
                                                            colorClass={
                                                                "gray-50-svg"
                                                            }
                                                        />
                                                    </span>
                                                </div>
                                                <Text type={"h1"}>
                                                    Your individual workspace{" "}
                                                    <br /> is active
                                                </Text>

                                                <div className="active-plan-modal-mid-data mt-20">
                                                    <Text
                                                        type={"body-text-3"}
                                                        fontWeight={
                                                            "medium-font"
                                                        }
                                                    >
                                                        {
                                                            "You can start running tests straight away."
                                                        }
                                                    </Text>

                                                    <Text
                                                        type={"body-text-3"}
                                                        fontWeight={
                                                            "semi-bold-font"
                                                        }
                                                    >
                                                        {
                                                            "Remember, you only pay for what you use."
                                                        }
                                                    </Text>
                                                </div>

                                                <div className="active-plan-modal-btn mt-32 mb-32">
                                                    <Button
                                                        type={"primary"}
                                                        size={"large"}
                                                        label={
                                                            "Go to dashboard"
                                                        }
                                                        onClick={() => {
                                                            //closeIndividualWelcomeModal(false);
                                                            //navigate(`/wsp/${workspaceid}`);
                                                            setOpenCongratsModal(
                                                                {
                                                                    open: false,
                                                                    type: null,
                                                                }
                                                            );
                                                            updateWorkspaceSuccessMessage(
                                                                {
                                                                    workspace_id:
                                                                        workspace.id,
                                                                },
                                                                ReactSession.get(
                                                                    "token"
                                                                )
                                                            ).then(
                                                                (response) => {}
                                                            );
                                                        }}
                                                    />
                                                </div>

                                                <div>
                                                    <Link
                                                        to={
                                                            "/wsp/" +
                                                            workspace.id +
                                                            "/upgrade-workspace"
                                                        }
                                                        className="link-text"
                                                    >
                                                        Upgrade to Team plan for
                                                        instant collaboration
                                                    </Link>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}
                            </>
                        }
                    />
                    
                    <AcceptedWorkspaceModal
                        open={acceptedWorkspaceModal.open}
                        cssClass={"accept-workspace-modal"}
                        close={() => {
                            setAcceptedWorkspaceModal({
                                ...acceptedWorkspaceModal,
                                open: false,
                            });
                        }}
                        body={
                            <>
                                <div className="active-plan-modal-data">
                                    <div className="active-plan-icon-hold mb-32">
                                        <span className="active-plan-icon-wrap">
                                            <Icon
                                                value={"group"}
                                                size={"large"}
                                                colorClass={"gray-50-svg"}
                                            />
                                        </span>
                                    </div>

                                    <Text type={"h2"}>
                                        {"Your team is waiting for you!"}
                                    </Text>
                                    <div className="active-plan-modal-mid-data mt-20">
                                        <Text
                                            type={"body-text-2"}
                                            fontWeight={"medium-font"}
                                            cssClasses={"gray-color"}
                                        >
                                            Welcome to{" "}
                                            <b className="black-color">
                                                {workspace.title}
                                            </b>
                                            .
                                            <br />
                                            Join your team members and start
                                            testing right away:
                                        </Text>

                                        <div className="teamplan-team-list mt-20">
                                            {teamMembers.map((user, index) => {
                                                let backgroundColor =
                                                    user.color;

                                                if (
                                                    !backgroundColor ||
                                                    user.status ==
                                                        "invitation_sent"
                                                ) {
                                                    backgroundColor = "#9C9C9C"; // Default color
                                                }
                                                return (
                                                    <CircularNameIcon
                                                        tooltip={
                                                            user && user.email
                                                                ? user.email
                                                                : ""
                                                        }
                                                        style={{
                                                            backgroundColor,
                                                        }}
                                                        key={index}
                                                        label={
                                                            user && user.name
                                                                ? user.name &&
                                                                  user.name
                                                                      .charAt(0)
                                                                      .toUpperCase()
                                                                : user.email
                                                                      .charAt(0)
                                                                      .toUpperCase()
                                                        }
                                                    />
                                                );
                                            })}
                                        </div>
                                        <div className="active-plan-modal-btn mt-32 mb-0">
                                            <Button
                                                type={"primary"}
                                                size={"large"}
                                                label={"Go to dashboard"}
                                                onClick={() => {
                                                    setAcceptedWorkspaceModal({
                                                        ...acceptedWorkspaceModal,
                                                        open: false,
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    />
                </>
            )}
            {openNewTestModal && (
                <TestSettingsModal
                    openModal={openNewTestModal}
                    closeModal={closeNewTestModal}
                    selectedMethodology={modalMethodology}
                    totalTests={totalTests}
                    workspace_id={workspaceid}
                    project_id={project_id}
                />
            )}

            {openChooseMethodologiesModal && (
                <ChooseMethodologyModal
                    workspace_type={workspace && workspace.type}
                    publishing_credits={publishing_credits}
                    publishing_credits_meth={publishing_credits_meth}
                    days_left={days_left}
                    openModal={openChooseMethodologiesModal}
                    closeModal={closeChooseMethodologiesModal}
                    enterTestDetails={(methodology) => {
                        setOpenNewTestModal(true);
                        closeChooseMethodologiesModal();
                        setModalMethodology(methodology);
                        if (methodology === "Preference Test") {
                            setTotalTests(
                                totalTestsCount.Preference_Test_count + 1
                            );
                        } else if (methodology === "Card Sorting") {
                            setTotalTests(
                                totalTestsCount.Card_Sorting_count + 1
                            );
                        } else if (methodology === "Tree Test") {
                            setTotalTests(totalTestsCount.Tree_Test_test + 1);
                        } else if (methodology === "Survey") {
                            setTotalTests(totalTestsCount.Survey_count + 1);
                        } else if (methodology === "Five Seconds Test") {
                            setTotalTests(
                                totalTestsCount.Five_Seconds_Test_count + 1
                            );
                        } else if (methodology === "First click") {
                            setTotalTests(
                                totalTestsCount.First_Click_count + 1
                            );
                        } else if (methodology === "Prototype test") {
                            setTotalTests(
                                totalTestsCount.prototype_test_count + 1
                            );
                        }
                    }}
                />
            )}

            <TestSummaryModal
                test_id={testSummaryModal.test_id}
                openModal={testSummaryModal.open}
                close={() => {
                    setTestSummaryModal({ ...testSummaryModal, open: false });
                }}
            />
            <CopyLinkModal
                link={copyLinkModal.link}
                resultLink={copyLinkModal.resultLink}
                password={copyLinkModal.password}
                test_id={copyLinkModal.test_id}
                test={copyLinkModal.test}
                openModal={copyLinkModal.open}
                close={() => {
                    setCopyLinkModal({ ...copyLinkModal, open: false });
                }}
            />
            <ConfirmationModal
                confirm_message={confirmModal.confirm_message}
                confirm_btn_title={confirmModal.confirm_btn_title}
                confirm_title={confirmModal.confirm_title}
                confirm_title_class={confirmModal.confirm_title_class}
                openModal={confirmModal.open}
                btnLoading={confirmationBtnLoading}
                close={() => {
                    setConfirmModal({ ...confirmModal, open: false });
                }}
                confirm={() => {
                    // setConfirmModal({ ...confirmModal, open: false });
                    if (confirmModal.confirmation_for === "testdelete") {
                        deleteTestApi(confirmModal.test_id);
                    } else if (confirmModal.confirmation_for === "endtest") {
                        endTestApi(confirmModal.test_id);
                    }
                }}
            />
            <NewFeatureModal
                openModal={openNewFeatureModal.open}
                title={"New feature alert"}
                body={
                    <>
                        <div className="new-feature-body-left">
                            <img
                                src={
                                    process.env.REACT_APP_URL +
                                    "img/prototype-test-black.svg"
                                }
                            />
                        </div>
                        <div className="new-feature-body-right">
                            <Text
                                type={"subtitle-2"}
                                fontWeight={"semi-bold-font"}
                            >
                                Prototype testing
                            </Text>
                            <Text
                                type={"body-text-3"}
                                fontWeight={"medium-font"}
                            >
                                Track and analyse user behaviour in your
                                prototype through clicks and navigation
                                patterns.{" "}
                                <a
                                    href="https://userq.com/testing-tools/prototype-test/"
                                    target="_blank"
                                >
                                    Learn more
                                </a>
                            </Text>
                        </div>
                    </>
                }
                btn={
                    <Button
                        type={"primary"}
                        size={"large"}
                        label="Create a prototype test"
                        iconRight={
                            <Icon
                                value="forward-arrow"
                                colorClass={"gray-50-svg forword-arrow"}
                                size="medium"
                            />
                        }
                        onClick={() => {
                            setOpenNewFeatureModal({
                                ...openNewFeatureModal,
                                open: false,
                            });

                            setModalMethodology("Prototype test");

                            setOpenNewTestModal(true);

                            updateNewFeature();
                        }}
                    />
                }
                close={() => {
                    setOpenNewFeatureModal({
                        ...openNewFeatureModal,
                        open: false,
                    });

                    updateNewFeature();
                }}
            />
            {project && (
                <>
                    <ProjectMembersModal
                        workspace_id={project.workspace_id}
                        project={project}
                        openModal={projectMembersModal.open}
                        closeModal={() => {
                            setProjectMembersModal({
                                ...projectMembersModal,
                                open: false,
                            });
                        }}
                        onUpdateProject={(project) => {
                            getTests();
                            setProject(project);
                            setProjectMembersModal({
                                ...projectMembersModal,
                                open: false,
                            });
                        }}
                    />
                    <MoveTestModal
                        openModal={moveTestModal.open}
                        closeModal={() => {
                            setMoveTestModal({ ...moveTestModal, open: false });
                        }}
                        test_name={moveTestModal.test_name}
                        test_id={moveTestModal.test_id}
                        workspace_id={moveTestModal.workspace_id}
                        project_id={moveTestModal.project_id}
                    />
                </>
            )}
            <HelpModal
                cssClass={"duplicate-test-modal"}
                open={duplicateModal.open}
                close={() => {
                    setDuplicateModal({ ...duplicateModal, open: false });
                }}
                heading={"Duplicate test"}
                btnLoading={duplicateBtnLoading}
                body={
                    <div className="prototype-criteria-modal-data">
                        <div className="mb-20">
                            <Text
                                cssClasses={"gray-text"}
                                type={"body-text-3"}
                                fontWeight={"medium-font"}
                            >
                                Choose whether you would like to keep your
                                recruitment selections when duplicating this
                                test
                            </Text>
                        </div>
                        <div className="duplicate-test-options">
                            <div className="mb-20">
                                <Box
                                    size={"large"}
                                    cssClasses={`${
                                        duplicateModal.duplicate_preference ==
                                        "clear"
                                            ? "active-box"
                                            : ""
                                    }`}
                                >
                                    <RadioButton
                                        isChecked={
                                            duplicateModal.duplicate_preference ==
                                            "clear"
                                                ? true
                                                : false
                                        }
                                        label={"Clear recruitment selections"}
                                        sublabel={
                                            "This will clear all recruitment and screening criteria for the duplicated test"
                                        }
                                        onChange={() => {
                                            setDuplicateModal({
                                                ...duplicateModal,
                                                duplicate_preference: "clear",
                                            });
                                        }}
                                    />
                                </Box>
                            </div>
                            <div className="mb-20">
                                <Box
                                    size={"large"}
                                    cssClasses={`${
                                        duplicateModal.duplicate_preference ==
                                        "keep"
                                            ? "active-box"
                                            : ""
                                    }`}
                                >
                                    <RadioButton
                                        isChecked={
                                            duplicateModal.duplicate_preference ==
                                            "keep"
                                                ? true
                                                : false
                                        }
                                        label={"Keep recruitment selections"}
                                        sublabel={
                                            "This will keep all recruitment and screening criteria for the duplicated test"
                                        }
                                        onChange={() => {
                                            setDuplicateModal({
                                                ...duplicateModal,
                                                duplicate_preference: "keep",
                                            });
                                        }}
                                    />
                                </Box>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center duplicate-test-modal-action">
                            <Button
                                label={"Duplicate test"}
                                type={"primary"}
                                size={"large"}
                                onClick={() => {
                                    setDuplicateModal({
                                        ...duplicateModal,
                                        open: false,
                                    });
                                    duplicateTestApi(duplicateModal.test_id);
                                }}
                                state={
                                    !duplicateModal.duplicate_preference
                                        ? "disabled"
                                        : "active"
                                }
                            />
                        </div>
                    </div>
                }
            />
            <InviteMembersModal
                open={inviteMemberModal.open}
                close={() => {
                    setInviteMemberModal({ open: false });
                }}
            />
        </LayoutResearcher>
    );
}
